import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

export const FETCH_PURCHASE_ORDER_ITEM_DELETE_API_ID =
  'FETCH_PURCHASE_ORDER_ITEM_DELETE_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_PURCHASE_ORDER_ITEM_DELETE_API_ID,
  ({ business_id, id }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/purchase-order-items/${id}`,
    }),
    method: 'DELETE',
  })
);

export default apiCall;
