import { SettingOutlined, SwitcherOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Modal, Space } from 'antd';
import useGoogleAuth from 'hooks/useGoogleAuth';
import { Link, useNavigate } from 'react-router-dom';
import useTenant from './use-tenant';

const TenantUserMenu = ({ user }) => {
  let navigate = useNavigate();
  const { business_id } = useTenant();
  const { signOut } = useGoogleAuth();
  const items = [
    {
      key: 'switch',
      label: 'Switch business',
      label: (
        <Link to="/">
          <SwitcherOutlined /> Switch business
        </Link>
      ),
    },
    {
      key: 'settings',
      label: (
        <Link to={`/businesses/${business_id}/settings`}>
          <SettingOutlined /> Business Settings
        </Link>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: 'Log out',
      onClick: () =>
        Modal.confirm({
          title: 'Are you sure?',
          onOk: () => {
            signOut();
            navigate('/login');
          },
        }),
    },
  ];

  return (
    <Dropdown arrow placement="bottomRight" menu={{ items }}>
      <Space className="cursor-pointer hover:bg-zinc-200 p-2 rounded-lg">
        {`${user.name || user.id} (${user.email})`}
        <Avatar style={{ verticalAlign: 'middle' }}>
          {user.name ? user.name.charAt(0) : 'U'}
        </Avatar>
      </Space>
    </Dropdown>
  );
};

export default TenantUserMenu;
