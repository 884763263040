import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import getOr from 'lodash/fp/getOr';

// IMPORTANT: DON'T USE THIS FUNCTION
// This is legacy
// Use useQuery of react-query instead
// https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
const useFetchApiList = (apiCall, options = {}, initialState = {}) => {
  const { resourceName = 'items', metaResourceName = 'meta' } = options;

  const [payload, setPayload] = useState({});
  const [delay, setDelay] = useState(true);

  const { data: rawData, isLoading, error, refetch, isRefetching } = useQuery({
    queryKey: [apiCall.queryKey, payload],
    queryFn: () => apiCall.queryFn(payload),
    enabled: false,
  });

  const fullData = getOr([], resourceName)(rawData);
  const metaData = getOr([], metaResourceName)(rawData);

  const load = newPayload => {
    setPayload(newPayload);
    setDelay(false);
  };

  useEffect(() => {
    if (!delay) {
      refetch();
    }
  }, [delay]);

  return {
    data: fullData,
    metaData,
    load,
    isLoading,
    refresh: refetch,
    refreshing: isRefetching,
    error,
  };
};

export default useFetchApiList;
