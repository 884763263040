import { PageHeader } from '@ant-design/pro-layout';
import BreadCrumb from 'common/ui/Breadcrumb';
import { useNavigate, useLocation } from 'react-router-dom';

const PageTitle = ({ title, subTitle, backUrl, showBreadscrumb, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <PageHeader
      className="site-page-header"
      onBack={() => {
        if (location.key !== 'default') {
          navigate(-1);
          return;
        }
        backUrl && navigate(backUrl);
      }}
      title={title}
      subTitle={subTitle}
      breadcrumb={showBreadscrumb ? <BreadCrumb /> : null}
      {...props}
    />
  );
};

export default PageTitle;
