import { StarFilled } from '@ant-design/icons';
import { Button, Space, Tag } from 'antd';
import { statusColors, statusLabels } from 'constants/labels';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { getTenantUrl } from 'utils/urlHelpers';

export const columnsGenerator = ({ business_id }) => [
  {
    title: 'Ordering',
    dataIndex: 'ordering',
    align: 'center',
  },
  {
    title: 'Image',
    dataIndex: 'image',
    render: value => value && <img width={100} src={value} />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (value, record) => {
      if (!record.is_featured) return value;
      return (
        <>
          <StarFilled spin style={{ color: '#ffec3d', border: '' }} /> {value}
        </>
      );
    },
  },
  {
    title: 'Product ID',
    dataIndex: 'code',
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => {
      return <Tag color={statusColors[status]}>{statusLabels[status]}</Tag>;
    },
  },

  {
    title: 'Created',
    dataIndex: 'created',
    render: value => formatDateTime(value),
  },
  {
    title: 'Modified',
    dataIndex: 'modified',
    render: value => formatDateTime(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link to={getTenantUrl(business_id, `/products/${record.id}`)}>
            View
          </Link>
        </Button>
        <Button shape="round">
          <Link to={getTenantUrl(business_id, `/products/${record.id}/edit`)}>
            Edit
          </Link>
        </Button>
      </Button.Group>
    ),
  },
];

export const variantColumns = ({ business_id, id, onDelete }) => [
  {
    title: 'Variant Name',
    dataIndex: 'name',
  },
  {
    title: 'Variant ID',
    dataIndex: 'code',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Last Updated At',
    dataIndex: 'modified',
    render: value => formatDateTime(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space>
        <Button>
          <Link
            to={getTenantUrl(
              business_id,
              `/products/${id}/variants/${record.id}/edit`
            )}
          >
            Edit
          </Link>
        </Button>
        <Button danger onClick={() => onDelete(record.id)}>
          Delete
        </Button>
      </Space>
    ),
  },
];
