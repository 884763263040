import { Button, Form, Input } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const AdminForm = ({ form, submiting, onFinish, initialValues = {}, name }) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'user_form'}
      onFinish={values => {
        if (
          values.password === '' ||
          values.password === undefined ||
          values.password === null
        ) {
          delete values.password;
        }
        return onFinish({ staff_account: values, permission: 'manager' });
      }}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        tooltip={{
          icon: <QuestionCircleOutlined />,
          title:
            'Applied only to emails not already associated with an account in the system',
        }}
      >
        <Input.Password placeholder="Enter user's password" />
      </Form.Item>

      <Form.Item
        name="name"
        label="Name"
        tooltip={{
          icon: <QuestionCircleOutlined />,
          title:
            'Applied only to emails not already associated with an account in the system',
        }}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Phone"
        rules={[
          {
            pattern: /^\+?\s*\d{1,3}([\s-]*\d{1,4}){2,4}$/,
            message: 'Please enter a valid phone number',
          },
        ]}
        tooltip={{
          icon: <QuestionCircleOutlined />,
          title:
            'Applied only to emails not already associated with an account in the system',
        }}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Add
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AdminForm;
