import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const CUSTOMER_DELETE_API = 'CUSTOMER_DELETE_API';

const apiCall = enhancedMakeFetchAction(
  CUSTOMER_DELETE_API,
  ({ business_id, id }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/customers/${id}`,
    }),
    method: 'DELETE',
    notify: true,
  })
);

export default apiCall;
