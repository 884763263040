import {
  AppstoreOutlined,
  CreditCardOutlined,
  InboxOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

export const openKeys = [
  'user',
  'order',
  'stock',
  'purchase',
  'payment',
  'expense',
  'journal',
];

function getItem(label, key, url, icon, children, type) {
  return {
    key: url ? url : key,
    icon,
    children,
    label: url ? <NavLink to={url}>{label}</NavLink> : label,
    type,
  };
}

export const getAdminItems = business_id => [
  getItem('Home', 'home', `/${business_id}`),
  getItem('Users', 'user', null, <UserOutlined />, [
    getItem('Customers', 'customer', `/${business_id}/customers`, null),
    getItem('Suppliers', 'supplier', `/${business_id}/suppliers`, null),
    getItem('Managers', 'staff', `/${business_id}/managers`, null),
    getItem('Admin', 'staff', `/${business_id}/administrators`, null),
  ]),
  getItem('Catalog', 'catalog', null, <AppstoreOutlined />, [
    getItem(
      'Categories',
      'category',
      `/${business_id}/categories`,
      <InboxOutlined />
    ),
    getItem(
      'Products',
      'product',
      `/${business_id}/products`,
      <InboxOutlined />
    ),
    getItem(
      'Branches',
      'branch',
      `/${business_id}/branches`,
      <InboxOutlined />
    ),
  ]),
  getItem(
    'Stock Tracking',
    'stock',
    `/${business_id}/stock-tracking`,
    <InboxOutlined />
  ),
  getItem('Sales', 'order', null, <ShoppingCartOutlined />, [
    getItem(
      'Quotation',
      'sales-quotation',
      `/${business_id}/sales-quotations`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Pending',
      'order',
      `/${business_id}/orders?status=1001`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      `/${business_id}/orders/completed`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Credit Note',
      'credit-notes',
      `/${business_id}/credit-notes`,
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Purchases', 'purchase', null, <ShoppingCartOutlined />, [
    getItem(
      'Purchase Order',
      'purchase-orders',
      `/${business_id}/purchase-orders`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Pending',
      'order',
      `/${business_id}/purchases?status=1001`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      `/${business_id}/purchases?status=1002`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Purchase Return',
      'purchase-returns',
      `/${business_id}/purchase-returns`,
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Expense', 'expense', null, <ShoppingCartOutlined />, [
    getItem(
      'Types',
      'expense-type',
      `/${business_id}/expense-types`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Pending',
      'order',
      `/${business_id}/expenses?status__in=1000,1001`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      `/${business_id}/expenses?status=1002`,
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Cash & Bank Book', 'payment', null, <CreditCardOutlined />, [
    getItem(
      'Accounts',
      'payment-accounts',
      `/${business_id}/payment/accounts`,
      <CreditCardOutlined />
    ),
    getItem(
      'Pending',
      'payment-transactions',
      `/${business_id}/payment/transactions?status__in=1000,1001`,
      <CreditCardOutlined />
    ),
    getItem(
      'Completed',
      'payment-transactions',
      `/${business_id}/payment/transactions?status=1002`,
      <CreditCardOutlined />
    ),
  ]),
  getItem('Journal', 'journal', null, <CreditCardOutlined />, [
    getItem(
      'Pending',
      'journal-entry-pending',
      `/${business_id}/journal-entries?status__in=1000,1001`,
      null
    ),
    getItem(
      'Completed',
      'journal-entry-completed',
      `/${business_id}/journal-entries?status=1002`,
      null
    ),
    getItem(
      'Cancelled',
      'journal-entry-cancelled',
      `/${business_id}/journal-entries?status__in=1003`,
      null
    ),
  ]),
];

export const getManagerItems = business_id => [
  getItem('Home', 'home', `/${business_id}`),
  getItem('Users', 'user', null, <UserOutlined />, [
    getItem('Customers', 'customer', `/${business_id}/customers`, null),
    getItem('Suppliers', 'supplier', `/${business_id}/suppliers`, null),
  ]),
  getItem('Sales', 'order', null, <ShoppingCartOutlined />, [
    getItem(
      'Quotation',
      'sales-quotation',
      `/${business_id}/sales-quotations`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Pending',
      'order',
      `/${business_id}/orders?status=1001`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      `/${business_id}/orders/completed`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Credit Note',
      'credit-notes',
      `/${business_id}/credit-notes`,
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Purchases', 'purchase', null, <ShoppingCartOutlined />, [
    getItem(
      'Pending',
      'order',
      `/${business_id}/purchase-orders?status=1001`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      `/${business_id}/purchase-orders?status=1002`,
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Expense', 'expense', null, <ShoppingCartOutlined />, [
    getItem(
      'Types',
      'expense-type',
      `/${business_id}/expense-types`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Pending',
      'order',
      `/${business_id}/expenses?status__in=1000,1001`,
      <ShoppingCartOutlined />
    ),
    getItem(
      'Completed',
      'order',
      `/${business_id}/expenses?status=1002`,
      <ShoppingCartOutlined />
    ),
  ]),
  getItem('Cash & Bank Book', 'payment', null, <CreditCardOutlined />, [
    getItem(
      'Accounts',
      'payment-accounts',
      `/${business_id}/payment/accounts`,
      <CreditCardOutlined />
    ),
    getItem(
      'Pending',
      'payment-transactions',
      `/${business_id}/payment/transactions?status__in=1000,1001`,
      <CreditCardOutlined />
    ),
    getItem(
      'Completed',
      'payment-transactions',
      `/${business_id}/payment/transactions?status=1002`,
      <CreditCardOutlined />
    ),
  ]),
];
