import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_SUPPLIER_LIST_API = 'FETCH_SUPPLIER_LIST_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_SUPPLIER_LIST_API,
  ({ business_id, ...params }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/users`,
      params: { ...params, account_type: 'supplier' },
    }),
    method: 'GET',
  })
);

export default apiCall;
