import { Button, Card, Space, Typography } from 'antd';
import apiCall from 'apiCalls/business';
import { useFetchApiGet } from 'common/reduxutils';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import DataTable from 'common/ui/DataTable';
import { accountTypes } from 'constants/invoice';
import { paymentMethods } from 'constants/paymentMethod';
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { formatDate } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { getTenantUrl } from 'utils/urlHelpers';

const { Title } = Typography;

const InvoiceItemDetail = ({ business_id, item }) => {
  const {
    data: companyInfo,
    load: fetchCompanyInfo,
    isLoading,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchCompanyInfo({ id: business_id });
  }, [business_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: item.code,
  });

  const payTo =
    item.payment_type === 'inflow' ? item.payment_account : item.user;
  const isToCashAccount =
    !payTo.account_type || payTo.account_type === accountTypes.PAYMENT;
  const payToName = isToCashAccount ? companyInfo?.company_name : payTo?.name;
  const payToRegNumber = isToCashAccount
    ? companyInfo?.registration_number
    : payTo?.registration_number;

  const payFrom =
    item.payment_type === 'inflow' ? item.user : item.payment_account;
  const isFromCashAccount =
    !payFrom.account_type || payFrom.account_type === accountTypes.PAYMENT;
  const payFromName = isFromCashAccount
    ? companyInfo?.company_name
    : payFrom?.name;
  const payFromRegNumber = isFromCashAccount
    ? companyInfo?.registration_number
    : payFrom?.registration_number;

  return (
    <>
      <Card
        title={<Title level={3}>Payment Voucher</Title>}
        extra={
          <Space>
            <Button>
              <Link
                to={getTenantUrl(
                  business_id,
                  `/payment/transactions/${item.id}`
                )}
              >
                View detail
              </Link>
            </Button>
            <Button>
              <Link onClick={handlePrint}>Print / Download</Link>
            </Button>
          </Space>
        }
        className="invoices"
        ref={componentRef}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="p-10">
            <div className="hidden print:!block line-clamp-2">
              <b className="uppercase">Payment Voucher</b>
            </div>
            <div className="m-auto">
              <div className="leading-3">
                <div>
                  <p>
                    <strong>{companyInfo.company_name}</strong>
                  </p>
                  <p>{companyInfo.address1}</p>
                  <p>{companyInfo.address2}</p>
                  <p>{companyInfo.address3}</p>
                </div>
                <hr className="my-5" />
                <div>
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <p>
                        <strong>Pay From:</strong>
                        <p>
                          <strong>
                            {payFromName?.toUpperCase()}
                            {payFromRegNumber && <> ({payFromRegNumber})</>}
                          </strong>
                        </p>
                        {isFromCashAccount ? (
                          <p>
                            <strong>{payFrom.code}</strong>
                          </p>
                        ) : (
                          <>
                            <p>{payFrom.address1}</p>
                            <p>{payFrom.address2}</p>
                            <p>{payFrom.address3}</p>
                          </>
                        )}
                      </p>
                      <br />
                      <p>
                        <strong>Pay To:</strong>
                        <p>
                          <strong>
                            {payToName?.toUpperCase()}
                            {payToRegNumber && <> ({payToRegNumber})</>}
                          </strong>
                        </p>
                        {isToCashAccount ? (
                          <p>
                            <strong>{payTo.code}</strong>
                          </p>
                        ) : (
                          <>
                            <p>{payTo.address1}</p>
                            <p>{payTo.address2}</p>
                            <p>{payTo.address3}</p>
                          </>
                        )}
                      </p>
                    </div>
                    <div>
                      <div className="grid grid-cols-3 gap-2">
                        <div>
                          <p>
                            <b>Invoice No.:</b>
                          </p>
                          <p>Date:</p>
                          <p>Reference No.:</p>
                        </div>
                        <div className="col-span-2">
                          <p>
                            <b>{item.code}</b>
                          </p>
                          <p>{formatDate(item.created)}</p>
                          <p>{item.reference_number}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <DataTable
                    rowKey="id"
                    columns={[
                      {
                        title: 'Particular',
                        dataIndex: 'particular',
                      },
                      {
                        title: 'Amount',
                        dataIndex: 'amount',
                        align: 'right',
                        render: value => formatCurrency(value),
                      },
                      {
                        title: 'Payment Mode',
                        dataIndex: 'payment_method',
                        render: value => paymentMethods[value],
                        align: 'center',
                      },
                      {
                        title: 'Tax',
                        dataIndex: 'tax',
                        align: 'right',
                        render: value => formatCurrency(value),
                      },
                    ]}
                    dataSource={item.tx_items}
                    totalItems={item.tx_items?.length}
                    currentPage={1}
                    defaultCurrent={1}
                  />
                </div>
              </div>

              <div className="mt-10">
                <div>
                  <hr className="my-5" />
                  <div className="flex gap-16 justify-end">
                    <div>
                      <p>Sub Total:</p>
                      <p>Discount Given:</p>
                      <p>Rounding Adjustment:</p>
                      <p>Total Amount:</p>
                    </div>
                    <div>
                      <p>{formatCurrency(item.sub_total)}</p>
                      <p>{formatCurrency(item.discount)}</p>
                      <p>{formatCurrency(item.rounding_adjustment)}</p>
                      <p>{formatCurrency(item.amount)}</p>
                    </div>
                  </div>
                </div>
                <div>
                  {item.notes && (
                    <div className="my-5">
                      <p>
                        <b>Notes: </b>
                      </p>
                      <ol>
                        <div>{item.notes}</div>
                      </ol>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default InvoiceItemDetail;
