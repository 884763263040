import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_BUSINESS_LIST_API_ID = 'FETCH_BUSINESS_LIST_API';

const apiCall = enhancedMakeFetchAction(FETCH_BUSINESS_LIST_API_ID, params => ({
  endpoint: generateEndpoint({
    path: `/admin/v1/businesses`,
    params,
  }),
  method: 'GET',
}));

export default apiCall;
