export const activeColors = {
  // 1000: '#fadb14',
  active: '#52c41a',
  inactive: '#f5222d',
};

export const activeLabels = {
  active: 'Active',
  inactive: 'Inactive',
};

export const statusColors = {
  1000: '#FFD700',
  1001: '#52c41a',
  1002: '#a9a9a9',
  1003: '#f5222d',
  1004: '#FFD700',
};

export const statusLabels = {
  1000: 'Pending',
  1001: 'Active',
  1002: 'In Active',
};

export const statusOptions = [
  { value: 1001, label: 'Drafted' },
  { value: 1002, label: 'Published' },
  { value: 1003, label: 'Trash' },
  { value: 1004, label: 'Private' },
];

export const DRAFT_STATUS = 1001;
export const PUBLISH_STATUS = 1002;
export const TRASH_STATUS = 1003;
export const PRIVATE_STATUS = 1004;

export const statusLabel = {
  1001: 'Draft',
  1002: 'Published',
  1003: 'Trash',
  1004: 'Private',
};

export const accessColors = {
  1001: '#52c41a',
  1002: '#FFD700',
  1003: '#a9a9a9',
};

export const accessLabel = {
  1001: 'Public',
  1002: 'Private',
  1003: 'Listed Only',
};

export default {};
