import { Form } from 'antd';
import { useFetchApiList, usePostApi } from 'common/reduxutils';
import PageTitle from 'components/PageTitle';
import useTenant from 'components/use-tenant';
import React from 'react';
import { useParams } from 'react-router-dom';
import { submitData } from 'utils/formData.js';

const AdminCreate = ({
  ItemForm,
  apiCall,
  title,
  listUrl,
  initialValues,
  resourceName,
  listResourceName,
  apiCallSuffix,
  params,
}) => {
  const [form] = Form.useForm();
  const urlParams = useParams();
  const { business_id } = useTenant();

  const {
    post: submitItem,
    isLoading: isSubmitting,
    error: submitItemError,
    rawData,
  } = usePostApi(apiCall.create, { resourceName });

  const { refresh } = useFetchApiList(apiCall.list, {
    apiCallSuffix,
    resourceName: listResourceName,
  });

  // Refetch the list data if create successfully
  React.useEffect(() => {
    if (isSubmitting === false && rawData) {
      refresh();
    }
  }, [isSubmitting, rawData]);

  const onFinish = (values, photoFields = ['photo']) => {
    submitData(submitItem, { business_id, ...values }, urlParams, photoFields);
  };

  return (
    <>
      <PageTitle title={title} backUrl={listUrl} />
      <ItemForm
        formMode="create"
        params={params}
        submiting={isSubmitting}
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        name="create_form"
      />
    </>
  );
};

export default AdminCreate;
