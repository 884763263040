import { Button, Card, Descriptions, Space, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/dateUtils';
import { getTenantUrl } from 'utils/urlHelpers';
import OrderItemDataTable from './OrderItemDataTable';

const { Title } = Typography;

const ItemDetail = ({ business_id, item }) => {
  return (
    <>
      <Card
        title={<Title level={3}>Purchase Detail</Title>}
        extra={
          <Space>
            <Button>
              <Link
                to={getTenantUrl(
                  business_id,
                  `/purchase-orders/${item.id}/edit`
                )}
              >
                Edit
              </Link>
            </Button>
            <Button>
              <Link
                to={getTenantUrl(
                  business_id,
                  `/purchase-orders/${item.id}/invoice`
                )}
              >
                Invoice
              </Link>
            </Button>
          </Space>
        }
      >
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Supplier">
            {item.user && (
              <Link
                to={getTenantUrl(business_id, `/suppliers/${item.user.id}`)}
              >
                {item.user.name}
              </Link>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Billing Address">
            {item.billing_address}
          </Descriptions.Item>

          <Descriptions.Item label="Contact Person">
            {item.contact_person}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {item.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Email Address">
            {item.email}
          </Descriptions.Item>

          <Descriptions.Item label="Purchase No.">
            {item.code}
          </Descriptions.Item>
          <Descriptions.Item label="Purchase Date">
            {formatDateTime(item.date, 'YYYY-MM-DD')}
          </Descriptions.Item>
          <Descriptions.Item label="Valid Date">
            {formatDateTime(item.valid_date, 'YYYY-MM-DD')}
          </Descriptions.Item>
          <Descriptions.Item label="Branch">
            {item.branch?.name}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card className="mt-4" title={<Title level={3}>Purchase Items</Title>}>
        <OrderItemDataTable
          dataSource={item.purchase_order_items || []}
          order={item}
        />
      </Card>
      <Card className="mt-4">
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Notes">{item.notes}</Descriptions.Item>
          <Descriptions.Item label="Admin Notes">
            {item.notes_admin}
          </Descriptions.Item>
          <Descriptions.Item label="Attachment">
            {item.file ? (
              <a href={item.file} target="_blank" rel="noreferrer">
                Open Attachment
              </a>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="Created Date">
            {formatDateTime(item.created)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated Date">
            {formatDateTime(item.modified)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
