import { Button, Card, Descriptions, Space, Typography } from 'antd';
import { accountTypes } from 'constants/invoice';
import { Link } from 'react-router-dom';
import { formatDateTime } from 'utils/dateUtils';
import { getTenantUrl } from 'utils/urlHelpers';
import PurchaseReturnItemDataTable from './PurchaseReturnItemDataTable';

const { Title } = Typography;

const ItemDetail = ({ business_id, item }) => {
  const isFromPaymentAccount = item.user?.account_type === accountTypes.PAYMENT;

  return (
    <>
      <Card
        title={<Title level={3}>Purchase Return Detail</Title>}
        extra={
          <Space>
            <Button>
              <Link
                to={getTenantUrl(business_id, `/credit-notes/${item.id}/edit`)}
              >
                Edit
              </Link>
            </Button>
            <Button>
              <Link
                to={getTenantUrl(
                  business_id,
                  `/credit-notes/${item.id}/invoice`
                )}
              >
                View invoice
              </Link>
            </Button>
          </Space>
        }
      >
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Customer">
            {item.user &&
              (isFromPaymentAccount ? (
                item.user.name
              ) : (
                <Link
                  to={getTenantUrl(business_id, `/customers/${item.user.id}`)}
                >
                  {item.user.name}
                </Link>
              ))}
          </Descriptions.Item>
          <Descriptions.Item label="Billing Address">
            {item.billing_address}
          </Descriptions.Item>

          <Descriptions.Item label="Contact Person">
            {item.contact_person}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number">
            {item.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Email Address">
            {item.customer_email}
          </Descriptions.Item>

          <Descriptions.Item label="Invoice No.">{item.code}</Descriptions.Item>
          <Descriptions.Item label="Date">{item.date}</Descriptions.Item>
          <Descriptions.Item label="Branch">
            {item.branch?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Reference No.">
            {item.reference_number}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Card title={<Title level={3}>Items</Title>} className="my-5">
        <PurchaseReturnItemDataTable
          dataSource={item.purchase_return_items}
          purchaseReturn={item}
        />
      </Card>

      <Card className="my-5">
        <Descriptions column={1} bordered>
          <Descriptions.Item label="Notes">{item.notes}</Descriptions.Item>
          <Descriptions.Item label="Personal Notes">
            {item.notes_admin}
          </Descriptions.Item>
          <Descriptions.Item label="Attachment">
            {item.file ? (
              <a href={item.file} target="_blank" rel="noreferrer">
                Open Attachment
              </a>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="Created Date">
            {formatDateTime(item.created)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated Date">
            {formatDateTime(item.modified)}
          </Descriptions.Item>
        </Descriptions>
      </Card>
    </>
  );
};

export default ItemDetail;
