import { getTenantUrl } from './urlHelpers';

export function getItemUrl(
  businessId: string,
  inventoryType: string,
  itemId: number
) {
  if (inventoryType === 'purchase')
    return getTenantUrl(businessId, `/purchases/${itemId}`);
  else if (inventoryType === 'sales')
    return getTenantUrl(businessId, `/orders/${itemId}`);
  else if (inventoryType === 'credit_note' || inventoryType === 'sales_return')
    return getTenantUrl(businessId, `/credit-notes/${itemId}`);
  else if (inventoryType === 'purchase_return')
    return getTenantUrl(businessId, `/purchase-returns/${itemId}`);
  return '';
}
