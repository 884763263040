import { Space, Typography } from 'antd';
import { getTenantUrl } from 'utils/urlHelpers';

import { formatDateTime } from 'utils/dateUtils';

export const columnsGenerator = ({ business_id, onDelete }) => [
  {
    title: 'ID',
    dataIndex: ['staff_account', 'id'],
  },
  {
    title: 'Name',
    dataIndex: ['staff_account', 'name'],
  },
  {
    title: 'Email',
    dataIndex: ['staff_account', 'email'],
  },
  {
    title: 'Phone',
    dataIndex: ['staff_account', 'phone'],
    align: 'right',
  },
  {
    title: 'Date Joined',
    dataIndex: ['staff_account', 'date_joined'],
    sorter: true,
    render: value => formatDateTime(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space>
        <Typography.Link
          onClick={() => {
            if (
              confirm(
                `Removing manager ${record.staff_account.email}. Are you sure?`
              )
            ) {
              onDelete({ business_id, id: record.id });
            }
          }}
        >
          Remove
        </Typography.Link>
      </Space>
    ),
  },
];
