import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

export const FETCH_SALE_QUOTATION_EDIT_API_ID = 'FETCH_SALE_QUOTATION_EDIT_API_ID';

const apiCall = enhancedMakeFetchAction(
  FETCH_SALE_QUOTATION_EDIT_API_ID,
  ({ business_id, id, payload }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/sales-quotations/${id}`,
    }),
    method: 'PATCH',
    body: payload,
    notify: true,
  })
);

export default apiCall;
