import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const USER_AUTH_API_ID = 'ACCOUNT/USER_AUTH_API_ID';

const apiCall = enhancedMakeFetchAction(USER_AUTH_API_ID, ({ payload }) => ({
  endpoint: generateEndpoint({ path: `/admin/v1/auth` }),
  method: 'POST',
  body: payload,
  notify: true,
}));

export default apiCall;
