import axios from 'utils/enhancedAxios';

const enhancedMakeFetchAction = (apiName, endpointGenerator) => {
  const apiCall = async params => {
    const options = endpointGenerator(params);
    const axiosOptions = {
      url: options.endpoint,
      method: options.method,
      data: options.body,
    };

    return await axios(axiosOptions);
  };

  return {
    queryKey: apiName,
    queryFn: apiCall,
  };
};

export { enhancedMakeFetchAction };
