import { Link, useParams, useSearchParams } from 'react-router-dom';
import React, { useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Card, Space, Typography } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { ToWords } from 'to-words';
import moment from 'moment';
import { flow, compact, join } from 'lodash/fp';

import { DetailPage } from 'common/ui/AdminCRUD/page';
import userApiCall from 'apiCalls/user';
import statementApiCall from 'apiCalls/userStatement';
import businessApiCall from 'apiCalls/business';
import { useFetchApiGet } from 'common/reduxutils';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import DataTable from 'common/ui/DataTable';
import { formatDate } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { formatFormalText } from 'utils/text';

const { Title, Text } = Typography;

const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: '',
      plural: '',
      symbol: 'MYR',
      fractionalUnit: {
        name: 'cent',
        plural: 'cents',
        symbol: '',
      },
    },
  },
});

const calculateDebtSummary = (transactions, currentDate) => {
  // Summary buckets
  const summary = {
    current: 0, // 0-30 days
    oneMonth: 0, // 31-60 days
    twoMonths: 0, // 61-90 days
    threeMonths: 0, // 91-120 days
    fourMonths: 0, // >120 days
  };

  // Process transactions
  transactions.forEach(transaction => {
    const ageInDays = currentDate.diff(moment(transaction.date), 'days');

    // Classify the transaction into appropriate aging bucket
    if (ageInDays <= 30) {
      summary.current += transaction.debit - transaction.credit;
    } else if (ageInDays <= 60) {
      summary.oneMonth += transaction.debit - transaction.credit;
    } else if (ageInDays <= 90) {
      summary.twoMonths += transaction.debit - transaction.credit;
    } else if (ageInDays <= 120) {
      summary.threeMonths += transaction.debit - transaction.credit;
    } else {
      summary.fourMonths += transaction.debit - transaction.credit;
    }
  });

  return summary;
};

const StatementItemDetail = ({ business_id, item: user }) => {
  const {
    data: companyInfo,
    load: fetchCompanyInfo,
    isLoading: isLoadingCompanyInfo,
  } = useFetchApiGet(businessApiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchCompanyInfo({ id: business_id });
  }, [business_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Statement',
  });

  const urlParams = useParams();
  const params = { business_id, status__in: '1001,1002' };
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const payload = { ...urlParams, ...searchParams, ...params };

  const { data = {}, isLoading: isLoadingData } = useQuery({
    queryKey: [statementApiCall.list.queryKey, payload],
    queryFn: () => statementApiCall.list.queryFn(payload),
  });
  const { items = [], paging } = data;
  const isLoading = isLoadingCompanyInfo || isLoadingData;

  const now = moment();
  const statementDate =
    searchParams['date__gte'] || searchParams['date__lte']
      ? [
          formatDate(searchParams['date__gte']) || 'Beginning',
          formatDate(searchParams['date__lte']) || 'Present',
        ].join(' - ')
      : formatDate(now);
  const currentBalance = items[0]?.balance || 0;
  const summary = calculateDebtSummary(items, now);

  return (
    <>
      <Card
        title={<Title level={3}>Statement</Title>}
        extra={
          <Space>
            <Button>
              <Link onClick={handlePrint}>Print / Download</Link>
            </Button>
          </Space>
        }
        className="invoices"
        ref={componentRef}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="p-10">
            <div className="m-auto">
              <div className="leading-3">
                <div>
                  <p>
                    <strong>
                      {companyInfo.company_name}
                      {companyInfo.registration_number && (
                        <> ({companyInfo.registration_number})</>
                      )}
                    </strong>
                  </p>
                  <p>{companyInfo.address1}</p>
                  <p>{companyInfo.address2}</p>
                  <p>{companyInfo.address3}</p>
                  {companyInfo.phone && <p>Tel: {companyInfo.phone}</p>}
                </div>

                <div className="flex justify-center items-center mb-5">
                  <p>
                    <strong>
                      {user.account_type === 'customer' ? 'Debtor' : 'Creditor'}{' '}
                      Statement
                    </strong>
                  </p>
                </div>

                <div className="grid grid-cols-12">
                  <div className="col-span-7">
                    <div className="grid grid-cols-4 gap-2">
                      <div>
                        <p>Debtor ID:</p>
                        <p>Debtor:</p>
                        <p>Address:</p>
                      </div>
                      <div className="col-span-3">
                        <p>{user.debtor_code}</p>
                        <p>{user.name}</p>
                        <p style={{ lineHeight: '18px' }}>
                          {flow(
                            compact,
                            join(<br />)
                          )([user.address1, user.address2, user.address3])}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div />
                  <div className="col-span-4">
                    <div className="grid grid-cols-2 gap-2">
                      <div>
                        <p>Statement Date:</p>
                        <p>Terms:</p>
                        <p>Page:</p>
                      </div>
                      <div>
                        <p>{statementDate}</p>
                        <p></p>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>

                <DataTable
                  rowKey="id"
                  columns={[
                    {
                      title: 'Date',
                      dataIndex: 'date',
                      render: value => formatDate(value),
                    },
                    {
                      title: 'Particular',
                      dataIndex: 'item_code',
                      render: (item_code, record) => (
                        <>
                          {formatFormalText(record.item_type)} {item_code}
                        </>
                      ),
                    },
                    {
                      title: 'Debit',
                      dataIndex: 'debit',
                      align: 'right',
                      render: value => (value > 0 ? formatCurrency(value) : ''),
                    },
                    {
                      title: 'Credit',
                      dataIndex: 'credit',
                      align: 'right',
                      render: value => (value > 0 ? formatCurrency(value) : ''),
                    },
                    {
                      title: 'Balance',
                      dataIndex: 'balance',
                      align: 'right',
                      render: value => formatCurrency(value),
                    },
                  ]}
                  dataSource={items.toReversed()}
                  totalItems={paging?.total_items}
                  currentPage={paging?.current_page}
                  defaultCurrent={1}
                  defaultPageSize={paging?.page_size || paging?.per_page}
                  pageSize={paging?.page_size || paging?.per_page}
                />

                <hr className="mt-5" />

                <div className="grid grid-cols-12">
                  <div className="col-span-9">
                    <p style={{ lineHeight: '18px' }}>
                      {`Ringgit Malaysia: ${toWords.convert(currentBalance)}`.toUpperCase()}
                    </p>
                  </div>
                  <div />
                  <div className="col-span-2">
                    <p className="mr-4 text-right">
                      {formatCurrency(currentBalance)}
                    </p>
                  </div>
                </div>

                <DataTable
                  rowKey="id"
                  columns={[
                    {
                      title: 'Current',
                      dataIndex: 'current',
                      align: 'center',
                      render: formatCurrency,
                    },
                    {
                      title: '1 Month',
                      dataIndex: 'oneMonth',
                      align: 'center',
                      render: formatCurrency,
                    },
                    {
                      title: '2 Months',
                      dataIndex: 'twoMonths',
                      align: 'center',
                      render: formatCurrency,
                    },
                    {
                      title: '3 Months',
                      dataIndex: 'threeMonths',
                      align: 'center',
                      render: formatCurrency,
                    },
                    {
                      title: '4 Months',
                      dataIndex: 'fourMonths',
                      align: 'center',
                      render: formatCurrency,
                    },
                  ]}
                  dataSource={[summary]}
                  totalItems={1}
                  currentPage={1}
                  defaultCurrent={1}
                />
                <Text>
                  We shall be gratefull if you will let us have payment as soon
                  as possible. Any discrepancy in this statement must be
                  reported to us in writing within 10 days.
                </Text>
              </div>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export const StatementPrintPage = props => {
  const { user_id } = useParams();

  return (
    <DetailPage
      title="Statement"
      apiCall={userApiCall}
      ItemDetail={StatementItemDetail}
      resourceName="items"
      options={{ id: user_id }}
      {...props}
    />
  );
};
