import { useQuery } from '@tanstack/react-query';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { getOr, isEmpty, some } from 'lodash/fp';
import { useMemo, useState } from 'react';

const DebounceSelect = ({
  apiCall,
  debounceTimeout = 800,
  displayFn,
  params = {},
  fieldNames = {},
  onSelect,

  initialOption = {}, // this for long list of options which not able to contain the selected initial option.
  ...props
}) => {
  const [search, setSearch] = useState();

  const { data, isLoading } = useQuery({
    queryKey: [apiCall.queryKey, { ...params, search }],
    queryFn: () => apiCall.queryFn({ ...params, search }),
  });

  const options = getOr([], 'items')(data);

  const debounceSearch = useMemo(
    () => debounce(setSearch, debounceTimeout),
    [setSearch, debounceTimeout]
  );

  // make sure it won't duplicate the initial option in most of the case that the initial option is already
  // in the options. Only add the initial option if it's not in the options.
  // it happens when the list is paging and the initial option is not in the current page.
  const allOptions =
    isEmpty(initialOption) ||
    isEmpty(getOr(null, fieldNames.value || 'value')(initialOption)) ||
    some(initialOption)(options)
      ? options
      : [initialOption, ...options];

  return (
    <Select
      showSearch={true}
      onSearch={debounceSearch}
      filterOption={false}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      options={displayFn ? displayFn(allOptions) : allOptions}
      allowClear
      onSelect={(value, option) => {
        console.log('selected', value, option);
        onSelect && onSelect(value, option);
        setSearch('');
      }}
      fieldNames={{
        label: 'label',
        value: 'value',
        options: 'options',
        groupLabel: 'label',
        ...fieldNames,
      }}
      {...props}
    />
  );
};

export default DebounceSelect;
