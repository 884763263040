import { Button, Form } from 'antd';
import DatePicker from 'common/form/UIDatePicker';
import QueryString from 'qs';
import { useSearchParams } from 'react-router-dom';
import { convertDateFormData, ensureDateFields } from 'utils/formData';

const StatementFilterForm = ({ form, name }) => {
  let [filterParams, setSearchParams] = useSearchParams();
  let initialValues = QueryString.parse(filterParams.toString());

  const handleSubmit = values => {
    const payload = convertDateFormData(values, ['date__gte', 'date__lte']);
    if (!payload.date__gte) {
      delete payload.date__gte;
    }
    if (!payload.date__lte) {
      delete payload.date__lte;
    }
    setSearchParams(payload);
  };

  return (
    <>
      <Form
        className="my-4"
        form={form}
        layout="inline"
        name={name || 'filter_form'}
        onFinish={handleSubmit}
        initialValues={ensureDateFields(initialValues, [
          'date__gte',
          'date__lte',
        ])}
      >
        <Form.Item name="date__gte" label="From">
          <DatePicker format={'YYYY-MM-DD'} />
        </Form.Item>
        <Form.Item name="date__lte" label="To">
          <DatePicker format={'YYYY-MM-DD'} />
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Filter
        </Button>
      </Form>
    </>
  );
};

export default StatementFilterForm;
