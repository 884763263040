import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const DELETE_BUSINESS_API_ID = 'DELETE_BUSINESS_API_ID';

const apiCall = enhancedMakeFetchAction(DELETE_BUSINESS_API_ID, ({ id }) => ({
  endpoint: generateEndpoint({
    path: `/admin/v1/businesses/${id}`,
  }),
  method: 'DELETE',
  notify: true,
}));

export default apiCall;
