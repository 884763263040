import apiCall from 'apiCalls/admin';
import { useMutation } from '@tanstack/react-query';
import { usePostApi } from 'common/reduxutils';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { useParams } from 'react-router-dom';
import ItemForm from './Form';
import { columnsGenerator } from './config';

export const AdminListPage = () => {
  const { mutate: deleteItem, isLoading: isDeleting } = useMutation({
    mutationFn: apiCall.delete.queryFn,
    onSuccess: () => {
      notification.open({
        type: 'success',
        message: 'Success',
      });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Something went wrong!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });
  const { business_id } = useTenant();

  return (
    <ListPage
      title="All Administrators"
      namespace="administrators"
      searchOptions={{ tooltip: 'Enter name, email or phone number' }}
      apiCall={apiCall}
      columnGenerator={() =>
        columnsGenerator({ business_id, onDelete: deleteItem })
      }
      createLabel="Add"
    />
  );
};

export const AdminCreatePage = props => (
  <CreatePage
    title="Administrators"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
