import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

// IMPORTANT: DON'T USE THIS FUNCTION
// This is legacy
// Use useMutation of react-query instead
// https://tanstack.com/query/latest/docs/framework/react/reference/useMutation
const usePostApi = (apiCall, options = {}) => {
  const navigate = useNavigate();

  if (!apiCall) {
    return { data: null, post: null, isLoading: false, error: 'No apiCall' };
  }

  const { resourceName = 'item', goBackOnSuccess = true } = options;

  const { mutate: post, isLoading, data: rawData, error } = useMutation({
    mutationFn: apiCall.queryFn,
    onSuccess: () => {
      if (goBackOnSuccess) {
        notification.open({
          type: 'success',
          message: 'Success',
        });
        navigate(-1);
      } else {
        notification.open({
          message: 'Saved',
        });
      }
    },
    onError: error => {
      const errors = error.data.errors || {};
      notification.open({
        type: 'error',
        message: 'Something went wrong!',
        // For APIs that return field-specific error messages
        // Until the errors are displayed in the respective form fields,
        // display a combined message with all field-specific error messages
        description: error.data.detail || (
          <>
            {Object.entries(errors).map(
              ([field, messages]) => {
                return (
                  <>
                    {field}: {messages.join(", ")}<br/>
                  </>
                )
              }
            )}
          </>
        ),
        duration: 10,
      });
    },
  });

  const { [resourceName]: data } = rawData || {};

  return {
    rawData,
    data,
    post,
    isLoading,
    error,
  };
};

export default usePostApi;
