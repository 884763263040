import apiCall from 'apiCalls/salesReturn';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import SalesReturnForm from './forms/SalesReturnForm';
import InvoiceItemDetail from './invoice';

export const SalesReturnListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Credit Notes"
      namespace="credit-notes"
      searchOptions={{ tooltip: 'Search by invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'users'] }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const SalesReturnDetailPage = props => (
  <DetailPage
    title="Credit Note Detail"
    namespace="credit-notes"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
  />
);

export const SalesReturnInvoicePage = props => (
  <DetailPage
    title="Credit Note Invoice"
    namespace="credit-notes"
    apiCall={apiCall}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
    {...props}
  />
);

export const SalesReturnEditPage = props => (
  <EditPage
    title="Edit Credit Note"
    namespace="credit-notes"
    apiCall={apiCall}
    ItemForm={SalesReturnForm}
    resourceName=""
    {...props}
  />
);

export const SalesReturnCreatePage = props => (
  <CreatePage
    title="New Credit Note"
    namespace="credit-notes"
    apiCall={apiCall}
    ItemForm={SalesReturnForm}
    resourceName=""
    {...props}
  />
);
