import { SITE_NAME } from 'config/env';
import { Helmet } from 'react-helmet-async';

const MetaDecorator = ({ title, description = '', showSiteName = true }) => {
  let siteName = title;
  if (showSiteName) {
    siteName = title ? `${title} | ${SITE_NAME}` : SITE_NAME;
  } else {
    siteName = title ? `${title}` : SITE_NAME;
  }

  return (
    <Helmet>
      <title>{siteName}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default MetaDecorator;
