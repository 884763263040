import { capitalize } from 'lodash';

export function formatFormalText(text: string): string {
  const replacements = ['_', '-'];
  let formattedText = text.trim();
  replacements.forEach(char => {
    formattedText = formattedText.replace(new RegExp(char, 'g'), ' ');
  });
  return capitalize(formattedText);
}
