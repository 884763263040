import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_CUSTOMER_EDIT_API = 'FETCH_CUSTOMER_EDIT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_CUSTOMER_EDIT_API,
  ({ business_id, id, payload }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/customers/${id}`,
    }),
    method: 'PATCH',
    body: payload,
    notify: true,
  })
);

export default apiCall;
