import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Input, InputNumber, notification } from 'antd';
import { map } from 'lodash/fp';
import { useEffect } from 'react';

import variantApiCall from 'apiCalls/productVariant';
import purchaseOrderApiCall from 'apiCalls/purchase';
import purchaseItemApiCall from 'apiCalls/purchaseOrderItem';
import FormattedNumberInput from 'common/form/FormattedNumberInput';
import DebounceSelect from 'common/ui/DebouceSelect';
import useTenant from 'components/use-tenant';
import { modalFormLayout } from 'utils/formConfig';
import { FormattedError } from 'utils/formData';

const usePurchaseOrderItemQuery = (business_id, id) => {
  return useQuery({
    queryKey: [purchaseItemApiCall.detail.queryKey, id],
    queryFn: () => {
      if (id) {
        return purchaseItemApiCall.detail.queryFn({ business_id, id });
      }

      return Promise.resolve('');
    },
  });
};

const ensureFormData = values => {
  return {
    ...values,
    product_variant: values?.product_variant?.id
      ? values.product_variant.id
      : values.product_variant,
    product: values.product?.id ? values.product.id : values.product,
  };
};

const PurchaseOrderItemForm = ({ form, name, closeModal, params }) => {
  const queryClient = useQueryClient();
  const { purchase, id } = params;
  const { business_id } = useTenant();

  const { data: purchaseOrderItem = {} } = usePurchaseOrderItemQuery(
    business_id,
    id
  );

  const { mutate: createItem } = useMutation({
    mutationFn: purchaseItemApiCall.create.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([purchaseOrderApiCall.detail.queryKey]);
      notification.open({ message: 'Saved' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const { mutate: updateItem } = useMutation({
    mutationFn: purchaseItemApiCall.edit.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([purchaseOrderApiCall.detail.queryKey]);
      queryClient.invalidateQueries([purchaseItemApiCall.detail.queryKey]);
      notification.open({ message: 'Saved' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const { mutate: deleteItem, isLoading: isDeleting } = useMutation({
    mutationFn: purchaseItemApiCall.delete.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([purchaseOrderApiCall.detail.queryKey]);
      notification.open({ message: 'Deleted' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  useEffect(() => {
    form.setFieldsValue(ensureFormData(purchaseOrderItem));
  }, [purchaseOrderItem]);

  return (
    <Form
      {...modalFormLayout}
      form={form}
      name={name || 'purchase_order_item_form'}
      onFinish={values => {
        const payload = {
          ...params,
          ...values,
          purchase: purchase,
        };

        if (id) {
          updateItem({ business_id, id, payload });
        } else {
          createItem({ business_id, payload });
        }
        closeModal();
        form.resetFields();
      }}
      initialValues={ensureFormData(purchaseOrderItem)}
      scrollToFirstError
    >
      <Form.Item
        name="product_variant"
        label="Product Variant"
        rules={[{ required: true }]}
      >
        <DebounceSelect
          apiCall={variantApiCall.list}
          placeholder="Select product"
          displayFn={map(variant => ({
            value: variant.id,
            label: `${variant.product.name} \\ ${variant.name}`,
            ...variant,
          }))}
          onSelect={(_selectedVariantId, selectedVariant) =>
            form.setFieldsValue({
              price: selectedVariant.price || selectedVariant.product?.price,
              product: selectedVariant.product?.id,
            })
          }
          params={{ business_id }}
        />
      </Form.Item>
      <Form.Item name="product" hidden>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
        <InputNumber placeholder="" precision={2} step={0.1} />
      </Form.Item>
      <Form.Item name="price" label="Price" rules={[{ required: true }]}>
        <InputNumber placeholder="" precision={2} step={0.1} />
      </Form.Item>
      <Form.Item name="discount_percentage" label="Discount (%)">
        <FormattedNumberInput
          placeholder=""
          precision={2}
          min={0}
          max={100}
          addonAfter="%"
        />
      </Form.Item>
      <Form.Item name="tax" label="Tax">
        <FormattedNumberInput placeholder="" addonAfter="RM" />
      </Form.Item>
    </Form>
  );
};

export default PurchaseOrderItemForm;
