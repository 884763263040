import { useQuery } from '@tanstack/react-query';
import { Form, Button } from 'antd';
import DataTable from 'common/ui/DataTable';
import MetaDecorator from 'components/MetaDecorator';
import PageTitle from 'components/PageTitle';
import StatementFilterForm from 'components/statement/StatementFilterForm';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { getTenantUrl } from 'utils/urlHelpers';

export const StatementPage = ({
  title,
  params,
  ItemCard,
  statementColumns,
  statementApiCall,
  FilterForm,
  ...props
}) => {
  const { business_id } = params;
  const [form] = Form.useForm();
  const urlParams = useParams();
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const payload = { ...urlParams, ...searchParams, ...params };

  const { data = {}, isLoading } = useQuery({
    queryKey: [statementApiCall.list.queryKey, payload],
    queryFn: () => statementApiCall.list.queryFn(payload),
  });
  const { items = [], paging, meta: metaData, stats } = data;

  const onFiltering = values => {
    var values = Object.fromEntries(
      Object.entries(values).filter(([k, v]) => v)
    );
  };

  return (
    <>
      <MetaDecorator title={title} />
      <PageTitle title={title} showBreadscrumb={true} />
      <div className="flex items-center justify-between">
        {FilterForm ? (
          <FilterForm
            form={form}
            onFinish={onFiltering}
            submiting={isLoading}
            initialValues={searchParams}
          />
        ) : (
          <StatementFilterForm
            form={form}
            onFinish={onFiltering}
            submiting={isLoading}
            initialValues={searchParams}
          />
        )}
        <Button type="primary">
          <Link
            to={getTenantUrl(
              business_id,
              `/users/${urlParams.user_id}/statement/print?${baseSearchParams.toString()}`
            )}
          >
            Statement
          </Link>
        </Button>
      </div>
      {ItemCard ? <ItemCard {...stats} {...metaData?.stats} /> : null}
      <DataTable
        rowKey="id"
        columns={statementColumns(props)}
        dataSource={items}
        totalItems={paging?.total_items}
        currentPage={paging?.current_page}
        defaultCurrent={1}
        defaultPageSize={paging?.page_size || paging?.per_page}
        pageSize={paging?.page_size || paging?.per_page}
        // dataFetcher={fetchItems}
        loading={isLoading}
        //filters keep filter params when go to another page
        filters={{ ...urlParams, ...searchParams, ...params }}
        {...props}
      />
    </>
  );
};
