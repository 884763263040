import axios from 'axios';

import { API_BASE_URL } from 'config/env';
import { getToken, deleteToken } from 'utils/auth';

// Create an Axios instance
const enhancedAxios = axios.create({
  baseURL: API_BASE_URL,
});

// Add a request interceptor to inject the Authorization header
enhancedAxios.interceptors.request.use(
  config => {
    config.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    // Get the JWT token from localStorage
    const token = getToken();

    // If the token exists, set the Authorization header
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    // Handle the error here if the request fails
    return Promise.reject(error);
  }
);

enhancedAxios.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response?.status == 401) {
      // Delete token from localStorage
      deleteToken();
      window.location.href = '/login?expired=true';
    }
    // Do something with response error
    return Promise.reject(error.response);
  }
);

export default enhancedAxios;
