import { Button, Form, Input, Space } from 'antd';
import apiCall from 'apiCalls/business';
import { usePostApi } from 'common/reduxutils';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getUser } from 'utils/auth';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({ form, submiting, onFinish, initialValues = {}, name }) => {
  const { id } = useParams();
  const user = getUser();

  const {
    post: deleteItem,
    isLoading: isDeleting,
    error: deleteItemError,
  } = usePostApi(apiCall.delete);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const isOwner = initialValues.owner === user.id;
  const canDeleteBusiness = id && isOwner;

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'business_form'}
      onFinish={values => onFinish(values)}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Please enter display name' }]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="company_name"
        label="Company Name"
        rules={[
          {
            required: true,
            message: 'Please enter company name to display in invoices',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="registration_number" label="Registration Number">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="email" label="Email">
        <Input type="email" placeholder="" />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Phone"
        rules={[
          {
            pattern: /^\+?\s*\d{1,3}([\s-]*\d{1,4}){2,4}$/,
            message: 'Please enter a valid phone number',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="address1" label="Address Line 1">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="address2" label="Address Line 2">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="address3" label="Address Line 3">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="bank_notes" label="Bank Notes">
        <Input.TextArea rows={3} placeholder="" />
      </Form.Item>
      <Form.Item name="tax_number" label="TIN">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="domain_name" label="Domain Name">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button type="primary" htmlType="submit" loading={submiting}>
            Save
          </Button>
          {canDeleteBusiness && (
            <Button
              danger
              loading={isDeleting}
              onClick={() => {
                if (confirm('Are you sure?')) {
                  deleteItem({ id });
                }
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
