import { Button, Card, Space, Typography } from 'antd';
import apiCall from 'apiCalls/business';
import { useFetchApiGet } from 'common/reduxutils';
import DataTable from 'common/ui/DataTable';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { accountTypes } from 'constants/invoice';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { formatDate } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { getTenantUrl } from 'utils/urlHelpers';
const { Title } = Typography;

const InvoiceItemDetail = ({ business_id, item }) => {
  const {
    data: companyInfo,
    load: fetchCompanyInfo,
    isLoading,
  } = useFetchApiGet(apiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchCompanyInfo({ id: business_id });
  }, [business_id]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: item.code,
  });

  const isCashPurchase = item.user?.account_type === accountTypes.PAYMENT;
  const purchaseName = isCashPurchase ? 'Cash Purchase' : item.user?.name;

  return (
    <>
      <Card
        title={<Title level={3}>Purchase Invoice {item.code}</Title>}
        extra={
          <Space>
            <Button>
              <Link to={getTenantUrl(business_id, `/purchases/${item.id}`)}>
                View detail
              </Link>
            </Button>
            <Button>
              <Link onClick={handlePrint}>Print / Download</Link>
            </Button>
          </Space>
        }
        className="invoices"
        ref={componentRef}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="p-10">
            <div className="hidden print:!block line-clamp-2">
              <b className="uppercase">Purchase Invoice {item.code}</b>
            </div>
            <div className="m-auto">
              <div className="leading-3">
                <div>
                  <p>
                    <strong>
                      {purchaseName.toUpperCase()}
                      {item.user?.registration_number && (
                        <> ({item.user.registration_number})</>
                      )}
                    </strong>
                  </p>
                  {isCashPurchase ? (
                    <p>{item.user?.code}</p>
                  ) : (
                    <>
                      <p>{item.user.address1}</p>
                      <p>{item.user.address2}</p>
                      <p>{item.user.address3}</p>
                    </>
                  )}
                  {item.user.phone && <p>Phone: {item.user.phone}</p>}
                </div>

                <hr className="my-5" />

                <div>
                  <div className="grid grid-cols-2 gap-2">
                    <div className="">
                      <p>
                        <strong>
                          {companyInfo.company_name}
                          {companyInfo.registration_number && (
                            <> ({companyInfo.registration_number})</>
                          )}
                        </strong>
                      </p>
                      <p>{companyInfo.address1}</p>
                      <p>{companyInfo.address2}</p>
                      <p>{companyInfo.address3}</p>
                    </div>
                    <div>
                      <div className="grid grid-cols-3 gap-2">
                        <div>
                          <p>
                            <b>Invoice No:</b>
                          </p>
                          <p>Date:</p>
                          <p>Reference No:</p>
                          <p>Branch:</p>
                        </div>
                        <div className="col-span-2">
                          <p>
                            <b>{item.code}</b>
                          </p>
                          <p>{formatDate(item.date)}</p>
                          <p>{item.reference_number || '-'}</p>
                          <p>{item.branch?.name || '-'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 my-2">
                    <div>
                      <p>Phone</p>
                      <p>Email</p>
                      <p>TIN</p>
                    </div>
                    <div className="col-span-2">
                      <p>: {companyInfo.phone || '-'}</p>
                      <p>: {companyInfo.email || '-'}</p>
                      <p>: {companyInfo.tax_number || '-'}</p>
                    </div>
                  </div>
                </div>

                <DataTable
                  rowKey="id"
                  columns={[
                    {
                      title: 'Product ID',
                      dataIndex: 'product',
                      render: product => product.code,
                    },
                    {
                      title: 'Product Name',
                      dataIndex: 'product',
                      render: product => product.name,
                    },
                    {
                      title: 'Quantity',
                      dataIndex: 'quantity',
                      align: 'right',
                    },
                    {
                      title: 'Price/Unit',
                      dataIndex: 'price',
                      render: value => formatCurrency(value),
                      align: 'right',
                    },
                    {
                      title: 'Amount',
                      dataIndex: 'total_amount',
                      render: value => formatCurrency(value),
                      align: 'right',
                    },
                  ]}
                  dataSource={item.purchase_items}
                  totalItems={item.purchase_items.length}
                  currentPage={1}
                  defaultCurrent={1}
                />
              </div>
              <div className="mt-10">
                <div className="">
                  <hr className="my-5" />
                  <div className="flex gap-16 justify-end">
                    <div>
                      <p>Sub Total:</p>
                      <p>Discount Given:</p>
                      <p>Rounding Adjustment:</p>
                      <p>Total Amount:</p>
                    </div>
                    <div className="pr-5">
                      <p>{formatCurrency(item.total_amount)}</p>
                      <p>{formatCurrency(item.discount)}</p>
                      <p>{formatCurrency(item.rounding_adjustment)}</p>
                      <p>{formatCurrency(item.total_amount)}</p>
                    </div>
                  </div>
                </div>
                <div>
                  {item.notes && (
                    <div className="my-5">
                      <p>
                        <b>Notes: </b>
                      </p>
                      <ol>
                        <div>{item.notes}</div>
                      </ol>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default InvoiceItemDetail;
