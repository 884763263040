import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_BUSINESS_EDIT_API_ID = 'FETCH_BUSINESS_EDIT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_BUSINESS_EDIT_API_ID,
  ({ id, payload }) => ({
    endpoint: generateEndpoint({
      path: `/admin/v1/businesses/${id}`,
    }),
    method: 'PATCH',
    body: payload,
    notify: true,
  })
);

export default apiCall;
