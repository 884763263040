import { Button, Form, Input } from 'antd';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const SupplierForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
}) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'user_form'}
      onFinish={values => {
        if (
          values.password === '' ||
          values.password === undefined ||
          values.password === null
        ) {
          delete values.password;
        }
        return onFinish(values);
      }}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Company Name"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="registration_number" label="Registration Number">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="person_in_charge" label="Person in charge">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Phone"
        rules={[
          { required: true, message: 'Phone number is required' },
          {
            pattern: /^\+?\s*\d{1,3}([\s-]*\d{1,4}){2,4}$/,
            message: 'Please enter a valid phone number',
          },
        ]}
      >
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: 'Please input the required field',
            whitespace: true,
          },
        ]}
      >
        <Input type="email" placeholder="" />
      </Form.Item>

      <Form.Item name="password" label="Password">
        <Input.Password placeholder="Enter to update user's password" />
      </Form.Item>

      <Form.Item name="address1" label="Address 1">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="address2" label="Address 2">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="address3" label="Address 3">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="tax_number" label="TIN">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="admin_notes" label="Admin Notes">
        <Input.TextArea placeholder="" />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SupplierForm;
