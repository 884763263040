import { createBrowserRouter } from 'react-router-dom';

import PermissionError from 'common/ui/PermissionError';
import GuestLayout from 'components/LayoutGuest';
import MainLayout from 'components/LayoutMain';
import TenantLayout from 'components/LayoutTenant';
import { AdminCreatePage, AdminListPage } from 'pages/Admin';
import AdminLoginPage from 'pages/AdminLogin';
import AppHome from 'pages/AppHome';
import { BranchCreatePage, BranchEditPage, BranchListPage } from 'pages/Branch';
import { BusinessCreatePage, BusinessEditPage } from 'pages/Business';
import BusinessHome from 'pages/BusinessHome';
import {
  CategoryCreatePage,
  CategoryEditPage,
  CategoryListPage,
} from 'pages/Category';
import {
  ExpenseCreatePage,
  ExpenseDetailPage,
  ExpenseEditPage,
  ExpenseInvoicePage,
  ExpenseListPage,
} from 'pages/Expense';
import {
  ExpenseTypeCreatePage,
  ExpenseTypeDetailPage,
  ExpenseTypeEditPage,
  ExpenseTypeListPage,
} from 'pages/ExpenseType';
import { ExpenseStatementPage } from 'pages/ExpenseType/ExpenseStatementPage';
import { JournalEntryListPage } from 'pages/JournalEntry';
import { ManagerCreatePage, ManagerListPage } from 'pages/Manager';
import {
  CompletedSalesOrderListPage,
  OrderCreatePage,
  OrderDetailPage,
  OrderEditPage,
  OrderInvoicePage,
  OrderListPage,
} from 'pages/Order';
import PageNotFound from 'pages/PageNotFound';
import {
  PaymentAccountCreatePage,
  PaymentAccountDetailPage,
  PaymentAccountEditPage,
  PaymentAccountListPage,
} from 'pages/PaymentAccount';
import { PaymentStatementPage } from 'pages/PaymentAccount/PaymentStatementPage';
import {
  PaymentTxCreatePage,
  PaymentTxDetailPage,
  PaymentTxEditPage,
  PaymentTxInvoicePage,
  PaymentTxListPage,
} from 'pages/PaymentTx';
import {
  ProductCreatePage,
  ProductDetailPage,
  ProductEditPage,
  ProductListPage,
} from 'pages/Product';
import { VariantStockListPage } from 'pages/ProductStock';
import { InventoryStatementPage } from 'pages/ProductStock/InventoryStatementPage';
import {
  PurchaseCreatePage,
  PurchaseDetailPage,
  PurchaseEditPage,
  PurchaseInvoicePage,
  PurchaseListPage,
} from 'pages/Purchase';
import {
  PurchaseOrderCreatePage,
  PurchaseOrderDetailPage,
  PurchaseOrderEditPage,
  PurchaseOrderInvoicePage,
  PurchaseOrderListPage,
} from 'pages/PurchaseOrder';
import {
  PurchaseReturnCreatePage,
  PurchaseReturnDetailPage,
  PurchaseReturnEditPage,
  PurchaseReturnInvoicePage,
  PurchaseReturnListPage,
} from 'pages/PurchaseReturn';
import {
  SalesQuotationCreatePage,
  SalesQuotationDetailPage,
  SalesQuotationEditPage,
  SalesQuotationInvoicePage,
  SalesQuotationListPage,
} from 'pages/SalesQuotation';
import {
  SalesReturnCreatePage,
  SalesReturnDetailPage,
  SalesReturnEditPage,
  SalesReturnInvoicePage,
  SalesReturnListPage,
} from 'pages/SalesReturn';
import {
  SupplierCreatePage,
  SupplierDetailPage,
  SupplierEditPage,
  SupplierListPage,
} from 'pages/Supplier';
import { SupplierStatementPage } from 'pages/Supplier/SupplierStatementPage';
import {
  UserCreatePage,
  UserDetailPage,
  UserEditPage,
  UserListPage,
} from 'pages/User';
import { CustomerStatementPage } from 'pages/User/CustomerStatementPage';
import { StatementPrintPage } from 'pages/User/StatementPrintPage';
import { VariantCreatePage, VariantEditPage } from 'pages/Variant';
import { RouterProvider } from 'react-router-dom';

const router = createBrowserRouter([
  {
    element: <GuestLayout />,
    children: [
      {
        path: '/login',
        element: <AdminLoginPage />,
      },
    ],
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <AppHome />,
      },
      {
        path: '/businesses',
        element: <AppHome />,
      },
      {
        path: '/businesses/new',
        element: <BusinessCreatePage />,
      },
      {
        path: '/businesses/:id',
        element: <BusinessEditPage />,
      },
      {
        path: '/businesses/:id/settings',
        element: <BusinessEditPage />,
      },
    ],
  },
  {
    element: <TenantLayout />,
    children: [
      {
        path: '/:business_id',
        element: <BusinessHome />,
      },
      {
        path: '/:business_id/products',
        element: <ProductListPage />,
      },
      {
        path: '/:business_id/products/create',
        element: <ProductCreatePage />,
      },
      {
        path: '/:business_id/products/:id',
        element: <ProductDetailPage />,
      },
      {
        path: '/:business_id/products/:product_id/variants/create',
        element: <VariantCreatePage />,
      },
      {
        path: '/:business_id/products/:product_id/variants/:id/edit',
        element: <VariantEditPage />,
      },
      {
        path: '/:business_id/products/:id/edit',
        element: <ProductEditPage />,
      },
      {
        path: '/:business_id/branches',
        element: <BranchListPage />,
      },
      {
        path: '/:business_id/branches/create',
        element: <BranchCreatePage />,
      },
      {
        path: '/:business_id/branches/:id/edit',
        element: <BranchEditPage />,
      },
      {
        path: '/:business_id/payment/accounts',
        element: <PaymentAccountListPage />,
      },
      {
        path: '/:business_id/payment/accounts/:id/statement/:user_id',
        element: <PaymentStatementPage />,
      },
      {
        path: '/:business_id/payment/accounts/:id',
        element: <PaymentAccountDetailPage />,
      },
      {
        path: '/:business_id/payment/accounts/:id/edit',
        element: <PaymentAccountEditPage />,
      },
      {
        path: '/:business_id/payment/accounts/create',
        element: <PaymentAccountCreatePage />,
      },
      {
        path: '/:business_id/payment/transactions',
        element: <PaymentTxListPage />,
      },
      {
        path: '/:business_id/payment/transactions/:id/edit',
        element: <PaymentTxEditPage />,
      },
      {
        path: '/:business_id/payment/transactions/:id/invoice',
        element: <PaymentTxInvoicePage />,
      },
      {
        path: '/:business_id/payment/transactions/:id',
        element: <PaymentTxDetailPage />,
      },
      {
        path: '/:business_id/payment/transactions/create/:type',
        element: <PaymentTxCreatePage />,
      },
      {
        path: '/:business_id/stock-tracking',
        element: <VariantStockListPage />,
      },
      {
        path: '/:business_id/stock-tracking/:product_variant_id',
        element: <InventoryStatementPage />,
      },
      {
        path: '/:business_id/orders',
        element: <OrderListPage />,
      },
      {
        path: '/:business_id/orders/completed',
        element: <CompletedSalesOrderListPage />,
      },
      {
        path: '/:business_id/orders/create',
        element: <OrderCreatePage />,
      },
      {
        path: '/:business_id/orders/:id',
        element: <OrderDetailPage />,
      },
      {
        path: '/:business_id/orders/:id/edit',
        element: <OrderEditPage />,
      },
      {
        path: '/:business_id/orders/:id/invoice',
        element: <OrderInvoicePage />,
      },
      {
        path: '/:business_id/credit-notes',
        element: <SalesReturnListPage />,
      },
      {
        path: '/:business_id/credit-notes/create',
        element: <SalesReturnCreatePage />,
      },
      {
        path: '/:business_id/credit-notes/:id',
        element: <SalesReturnDetailPage />,
      },
      {
        path: '/:business_id/credit-notes/:id/edit',
        element: <SalesReturnEditPage />,
      },
      {
        path: '/:business_id/credit-notes/:id/invoice',
        element: <SalesReturnInvoicePage />,
      },
      {
        path: '/:business_id/sales-quotations',
        element: <SalesQuotationListPage />,
      },
      {
        path: '/:business_id/sales-quotations/create',
        element: <SalesQuotationCreatePage />,
      },
      {
        path: '/:business_id/sales-quotations/:id',
        element: <SalesQuotationDetailPage />,
      },
      {
        path: '/:business_id/sales-quotations/:id/edit',
        element: <SalesQuotationEditPage />,
      },
      {
        path: '/:business_id/sales-quotations/:id/invoice',
        element: <SalesQuotationInvoicePage />,
      },
      {
        path: '/:business_id/purchase-orders',
        element: <PurchaseOrderListPage />,
      },
      {
        path: '/:business_id/purchase-orders/create',
        element: <PurchaseOrderCreatePage />,
      },
      {
        path: '/:business_id/purchase-orders/:id',
        element: <PurchaseOrderDetailPage />,
      },
      {
        path: '/:business_id/purchase-orders/:id/edit',
        element: <PurchaseOrderEditPage />,
      },
      {
        path: '/:business_id/purchase-orders/:id/invoice',
        element: <PurchaseOrderInvoicePage />,
      },
      {
        path: '/:business_id/purchases',
        element: <PurchaseListPage />,
      },
      {
        path: '/:business_id/purchases/create',
        element: <PurchaseCreatePage />,
      },
      {
        path: '/:business_id/purchases/:id',
        element: <PurchaseDetailPage />,
      },
      {
        path: '/:business_id/purchases/:id/edit',
        element: <PurchaseEditPage />,
      },
      {
        path: '/:business_id/purchases/:id/invoice',
        element: <PurchaseInvoicePage />,
      },
      {
        path: '/:business_id/purchase-returns',
        element: <PurchaseReturnListPage />,
      },
      {
        path: '/:business_id/purchase-returns/create',
        element: <PurchaseReturnCreatePage />,
      },
      {
        path: '/:business_id/purchase-returns/:id',
        element: <PurchaseReturnDetailPage />,
      },
      {
        path: '/:business_id/purchase-returns/:id/edit',
        element: <PurchaseReturnEditPage />,
      },
      {
        path: '/:business_id/purchase-returns/:id/invoice',
        element: <PurchaseReturnInvoicePage />,
      },
      { path: '/:business_id/categories', element: <CategoryListPage /> },
      {
        path: '/:business_id/categories/create',
        element: <CategoryCreatePage />,
      },
      {
        path: '/:business_id/categories/:id/edit',
        element: <CategoryEditPage />,
      },
      { path: '/:business_id/orders', element: <OrderListPage /> },
      { path: '/:business_id/orders/:id', element: <OrderDetailPage /> },
      { path: '/:business_id/orders/:id/edit', element: <OrderEditPage /> },

      { path: '/:business_id/customers', element: <UserListPage /> },
      { path: '/:business_id/customers/create', element: <UserCreatePage /> },
      { path: '/:business_id/customers/:id', element: <UserDetailPage /> },
      { path: '/:business_id/customers/:id/edit', element: <UserEditPage /> },
      {
        path: '/:business_id/customers/:user_id/statement',
        element: <CustomerStatementPage />,
      },
      { path: '/:business_id/suppliers', element: <SupplierListPage /> },
      {
        path: '/:business_id/suppliers/create',
        element: <SupplierCreatePage />,
      },
      { path: '/:business_id/suppliers/:id', element: <SupplierDetailPage /> },
      {
        path: '/:business_id/suppliers/:id/edit',
        element: <SupplierEditPage />,
      },
      {
        path: '/:business_id/suppliers/:user_id/statement',
        element: <SupplierStatementPage />,
      },
      {
        path: '/:business_id/users/:user_id/statement/print',
        element: <StatementPrintPage />,
      },

      { path: '/:business_id/administrators', element: <AdminListPage /> },
      {
        path: '/:business_id/administrators/create',
        element: <AdminCreatePage />,
      },

      { path: '/:business_id/managers', element: <ManagerListPage /> },
      { path: '/:business_id/managers/create', element: <ManagerCreatePage /> },

      { path: '/:business_id/expense-types', element: <ExpenseTypeListPage /> },
      {
        path: '/:business_id/expense-types/:id',
        element: <ExpenseTypeDetailPage />,
      },
      {
        path: '/:business_id/expense-types/:id/edit',
        element: <ExpenseTypeEditPage />,
      },
      {
        path: '/:business_id/expense-types/:id/statement',
        element: <ExpenseStatementPage />,
      },
      {
        path: '/:business_id/expense-types/create',
        element: <ExpenseTypeCreatePage />,
      },

      { path: '/:business_id/expenses', element: <ExpenseListPage /> },
      { path: '/:business_id/expenses/create', element: <ExpenseCreatePage /> },
      { path: '/:business_id/expenses/:id', element: <ExpenseDetailPage /> },
      { path: '/:business_id/expenses/:id/edit', element: <ExpenseEditPage /> },
      {
        path: '/:business_id/expenses/:id/invoice',
        element: <ExpenseInvoicePage />,
      },
      {
        path: '/:business_id/journal-entries',
        element: <JournalEntryListPage />,
      },
      // Add other routes as needed, following the same pattern...
    ],
  },
  {
    path: '/permission',
    element: <PermissionError />,
  },
  {
    path: '/404',
    element: <PageNotFound />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
