import MetaDecorator from 'components/MetaDecorator';
import { SITE_NAME } from 'config/env';

const PermissionError = () => (
  <>
    <MetaDecorator title={`Permission denied | ${SITE_NAME}`} />
    <p style={{ fontSize: '24px', textAlign: 'center', color: 'red' }}>
      You do not have permission to access
    </p>
  </>
);

export default PermissionError;
