import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form, Input, InputNumber, notification } from 'antd';
import { map } from 'lodash/fp';
import { useEffect } from 'react';

import variantApiCall from 'apiCalls/productVariant';
import orderApiCall from 'apiCalls/purchaseReturn';
import orderItemApiCall from 'apiCalls/purchaseReturnItem';
import FormattedNumberInput from 'common/form/FormattedNumberInput';
import DebounceSelect from 'common/ui/DebouceSelect';
import useTenant from 'components/use-tenant';
import { modalFormLayout } from 'utils/formConfig';

const useOrderItemQuery = (business_id, id) => {
  return useQuery({
    queryKey: [orderItemApiCall.detail.queryKey, id],
    queryFn: () => {
      if (id) {
        return orderItemApiCall.detail.queryFn({ business_id, id });
      }

      return Promise.resolve('');
    },
  });
};

const ensureFormData = values => {
  return {
    ...values,
    product_variant: values?.product_variant?.id
      ? values.product_variant.id
      : values.product_variant,
    product: values.product?.id ? values.product.id : values.product,
  };
};

const OrderItemForm = ({ form, name, closeModal, params }) => {
  const { order, id } = params;
  const queryClient = useQueryClient();
  const { business_id } = useTenant();

  const { data: orderItem = {} } = useOrderItemQuery(business_id, id);

  const { mutate: createItem } = useMutation({
    mutationFn: orderItemApiCall.create.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([orderApiCall.detail.queryKey]);
      notification.open({ message: 'Saved' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });

  const { mutate: updateItem } = useMutation({
    mutationFn: orderItemApiCall.edit.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([orderApiCall.detail.queryKey]);
      queryClient.invalidateQueries([orderItemApiCall.detail.queryKey]);

      notification.open({ message: 'Saved' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });

  useEffect(() => {
    form.setFieldsValue(ensureFormData(orderItem));
  }, [orderItem]);

  return (
    <Form
      {...modalFormLayout}
      form={form}
      name={name || `order_item_form_${id}`}
      onFinish={values => {
        const payload = {
          ...params,
          ...values,
          order: order,
          discount_percentage: values.discount_percentage || 0,
          tax: values.tax || 0,
        };

        if (id) {
          updateItem({ business_id, id, payload });
        } else {
          createItem({ business_id, payload });
        }

        closeModal();
        form.resetFields();
      }}
      initialValues={ensureFormData(orderItem)}
      scrollToFirstError
    >
      <Form.Item
        name="product_variant"
        label="Product Variant"
        rules={[{ required: true }]}
      >
        <DebounceSelect
          apiCall={variantApiCall.list}
          placeholder="Select product variant"
          displayFn={map(variant => ({
            value: variant.id,
            label: `${variant.product.name} \\ ${variant.name}`,
            ...variant,
          }))}
          onSelect={(_selectedVariantId, selectedVariant) =>
            form.setFieldsValue({
              price: selectedVariant.price || selectedVariant.product?.price,
              product: selectedVariant.product?.id,
            })
          }
          params={{ business_id }}
        />
      </Form.Item>
      <Form.Item name="product" hidden>
        <Input type="hidden" />
      </Form.Item>

      <Form.Item name="quantity" label="Quantity" rules={[{ required: true }]}>
        <InputNumber placeholder="" precision={2} step={1} />
      </Form.Item>

      <Form.Item name="price" label="Price" required>
        <FormattedNumberInput placeholder="" addonAfter="RM" />
      </Form.Item>

      <Form.Item name="discount_percentage" label="Discount (%)">
        <FormattedNumberInput
          placeholder=""
          precision={2}
          min={0}
          max={100}
          addonAfter="%"
        />
      </Form.Item>

      <Form.Item name="tax" label="Tax">
        <FormattedNumberInput placeholder="" addonAfter="RM" />
      </Form.Item>
    </Form>
  );
};

export default OrderItemForm;
