import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Switch, Upload } from 'antd';
import { useEffect } from 'react';

import apiCall from 'apiCalls/category';
import DebounceSelect from 'common/ui/DebouceSelect';
import useTenant from 'components/use-tenant';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {
    ordering: 0,
    is_featured: false,
  },
  name,
}) => {
  const { business_id } = useTenant();
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  useEffect(() => {
    if (initialValues.category && initialValues.category.id) {
      form.setFieldsValue({
        ...initialValues,
        category: initialValues.category.id,
      });
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'product_form'}
      onFinish={values => onFinish(values, ['image'])}
      scrollToFirstError
    >
      <Form.Item name="category" label="Category">
        <DebounceSelect
          apiCall={apiCall.list}
          params={
            initialValues.category
              ? { business_id, id: initialValues.category.id }
              : { business_id }
          }
          placeholder="Select Category"
          fieldNames={{ label: 'name', value: 'id' }}
        />
      </Form.Item>

      <Form.Item name="name" label="Name (English)">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="name_ms" label="Name (Malay)">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="name_zh" label="Name (Chinese)">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="description" label="Description (English)">
        <Input.TextArea rows={10} />
      </Form.Item>
      <Form.Item name="description_ms" label="Description (Malay)">
        <Input.TextArea rows={10} />
      </Form.Item>
      <Form.Item name="description_zh" label="Description (Chinese)">
        <Input.TextArea rows={10} />
      </Form.Item>

      <Form.Item name="unit" label="Unit">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="price" label="Price per unit">
        <InputNumber placeholder="" precision={2} step={0.1} />
      </Form.Item>

      <Form.Item name="ordering" label="Ordering">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item
        name="is_featured"
        label="Is Featured?"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        name="image"
        label="Image"
        valuePropName="fileList "
        getValueFromEvent={normFile}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
