import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_ORDER_ITEM_EDIT_API_ID = 'FETCH_ORDER_ITEM_EDIT_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_ORDER_ITEM_EDIT_API_ID,
  ({ business_id, id, payload }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/order-items/${id}`,
    }),
    method: 'PATCH',
    body: payload,
  })
);

export default apiCall;
