import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Form, Input, InputNumber, notification, Space } from 'antd';
import { useEffect } from 'react';

import expenseApiCall from 'apiCalls/expense';
import expenseItemApiCall from 'apiCalls/expenseItem';
import FormattedNumberInput from 'common/form/FormattedNumberInput';
import useTenant from 'components/use-tenant';
import { modalFormLayout, tailFormItemLayout } from 'utils/formConfig';
import { ensureDateFields, FormattedError } from 'utils/formData';

const useExpenseItemQuery = (business_id, id) => {
  return useQuery({
    queryKey: [expenseItemApiCall.detail.queryKey, id],
    queryFn: () => {
      if (id) {
        return expenseItemApiCall.detail.queryFn({ business_id, id });
      }

      return Promise.resolve('');
    },
  });
};

const ExpenseItemForm = ({ form, name, closeModal, params }) => {
  const queryClient = useQueryClient();
  const { expenseId, id } = params;
  const { business_id } = useTenant();

  const { data: initialValues = {}, isLoading } = useExpenseItemQuery(
    business_id,
    id
  );

  const { mutate: deleteItem, isLoading: isDeleting } = useMutation({
    mutationFn: expenseItemApiCall.delete.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([expenseApiCall.detail.queryKey]);
      notification.open({ message: 'Deleted' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const { mutate: createItem } = useMutation({
    mutationFn: expenseItemApiCall.create.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([expenseApiCall.detail.queryKey]);
      notification.open({ message: 'Saved' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const { mutate: updateItem, isLoading: isUpdating } = useMutation({
    mutationFn: expenseItemApiCall.edit.queryFn,
    onSuccess: () => {
      queryClient.invalidateQueries([expenseApiCall.detail.queryKey]);
      queryClient.invalidateQueries([expenseItemApiCall.detail.queryKey]);
      notification.open({ message: 'Saved' });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  useEffect(() => {
    const values = ensureDateFields(initialValues);
    if (values.branch && values.branch.id) {
      form.setFieldsValue({
        ...values,
        branch: values.branch.id,
      });
    } else {
      form.setFieldsValue(values);
    }
  }, [form, initialValues]);

  return (
    <Form
      {...modalFormLayout}
      form={form}
      name={name || 'expense_item_form'}
      onFinish={values => {
        const payload = { ...params, ...values, expense: expenseId };
        if (id) {
          updateItem({ business_id, id, payload });
        } else {
          createItem({ business_id, payload });
        }

        closeModal();
        form.resetFields();
      }}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Form.Item name="particular" label="Particular">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="price" label="Price">
        <FormattedNumberInput placeholder="" addonAfter="RM" />
      </Form.Item>
      <Form.Item name="quantity" label="Quantity">
        <InputNumber placeholder="" precision={2} step={1} />
      </Form.Item>
      <Form.Item name="tax" label="Tax">
        <FormattedNumberInput placeholder="" addonAfter="RM" />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          {initialValues.id && (
            <Button
              danger
              loading={isDeleting}
              onClick={() => {
                if (confirm('Are you sure?')) {
                  deleteItem({ business_id, id });
                  closeModal();
                }
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ExpenseItemForm;
