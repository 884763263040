import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

export const CREATE_SALES_RETURN_API_ID = 'CREATE_SALES_RETURN_API_ID';

const apiCall = enhancedMakeFetchAction(
  CREATE_SALES_RETURN_API_ID,
  ({ business_id, payload }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/sales-returns`,
    }),
    method: 'POST',
    body: payload,
  })
);

export default apiCall;
