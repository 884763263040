import apiCall from 'apiCalls/manager';
import { useMutation } from '@tanstack/react-query';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import ItemForm from './Form';
import { columnsGenerator } from './config';

export const ManagerListPage = () => {
  const { mutate: deleteItem, isLoading: isDeleting } = useMutation({
    mutationFn: apiCall.delete.queryFn,
    onSuccess: () => {
      notification.open({
        type: 'success',
        message: 'Success',
      });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Something went wrong!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Managers"
      namespace="managers"
      searchOptions={{ tooltip: 'Enter name, email or phone number' }}
      apiCall={apiCall}
      columnGenerator={() =>
        columnsGenerator({ business_id, onDelete: deleteItem })
      }
      createLabel="Add"
    />
  );
};

export const ManagerCreatePage = () => (
  <CreatePage
    title="Managers"
    namespace="managers"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);
