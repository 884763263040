import apiCall from 'apiCalls/business';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import { getUser } from 'utils/auth';
import useTenant from 'components/use-tenant';
import ItemForm from './Form';

export const BusinessEditPage = props => (
  <EditPage
    title="Edit Businesses"
    apiCall={apiCall}
    listUrl={`/`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);

export const BusinessCreatePage = props => (
  <CreatePage
    title="New Businesses"
    apiCall={apiCall}
    listUrl={`/`}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
