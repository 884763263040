import { deleteToken, getToken, getUser } from 'utils/auth';

export default function useGoogleAuth() {
  const token = getToken();

  const user = getUser();
  const isAuthenticated = user && user.email;

  const signOut = () => deleteToken();

  return {
    user,
    token,
    isAuthenticated,
    loading: false,
    signOut,
  };
}
