import { concat, flow, groupBy, keys, reduce, uniq } from 'lodash/fp';
import moment from 'moment';

import { mapWithIndex } from './lodashFP';
import { formatFormalText } from './text';

export const convertFormData = values => {
  const keys = Object.keys(values);
  const data = new FormData();
  keys.forEach(key => {
    if (values[key]) {
      data.append(key, values[key]);
    } else {
      data.append(key, '');
    }
  });
  return data;
};

export const normalizeData = values => {
  const keys = Object.keys(values);
  keys.forEach(key => {
    if (typeof values[key] === 'undefined') {
      values[key] = null;
    }
  });
  return values;
};

const DATE_FORTMAT = 'YYYY-MM-DD';

export const ensureDateFields = (values, fields = []) => {
  return {
    ...values,
    ...Object.assign(
      {},
      ...fields.map(key =>
        values[key]
          ? { [key]: moment(values[key], DATE_FORTMAT) }
          : { [key]: null }
      )
    ),
  };
};

export const ensureDateTimeFields = (values, fields = []) => {
  return {
    ...values,
    ...Object.assign(
      {},
      ...fields.map(key =>
        values[key] ? { [key]: moment(values[key]) } : { [key]: null }
      )
    ),
  };
};

export const convertDateFormData = (values, fields = []) => {
  return {
    ...values,
    ...Object.assign(
      {},
      ...fields.map(key =>
        values[key]
          ? { [key]: values[key].format(DATE_FORTMAT) }
          : { [key]: null }
      )
    ),
  };
};

export const submitData = async (
  submitItem,
  values,
  urlParams,
  photoFields = []
) => {
  try {
    let submitData = normalizeData(values);
    let isConvertFormData = false;

    for (let i = 0; i < photoFields.length; i++) {
      const field = photoFields[i];
      const fieldValue = values[field];
      if (
        fieldValue &&
        fieldValue.at(-1) &&
        fieldValue.at(-1).originFileObj instanceof File
      ) {
        submitData[field] = values[field][0].originFileObj;
        isConvertFormData = true;
      } else {
        delete submitData[field];
      }
    }

    const { id, ...rest } = submitData;
    let payload = rest;
    if (isConvertFormData) {
      payload = convertFormData(rest);
    }

    submitItem({ id, ...urlParams, payload });
  } catch (error) {
    console.error(error.message);
  }
};

export const FormattedError = ({ error }) => {
  const errors = error.data.errors || {};

  return (
    error.data.detail || (
      <>
        {Object.entries(errors).map(([field, messages], index) => {
          return (
            <span key={index}>
              {`${field}: ${messages.join(', ')}`}
              <br />
            </span>
          );
        })}
      </>
    )
  );
};

const sortByArray = array => object => {
  return flow(
    keys,
    concat(array),
    uniq,
    reduce(
      (result, item) => ({
        ...result,
        [item]: object[item],
      }),
      {}
    )
  )(object);
};

export const userSelectDisplayFn = flow(
  groupBy('account_type'),
  sortByArray(['customer', 'supplier', 'payment']),
  mapWithIndex((accounts, account_type) => ({
    label:
      account_type == 'payment'
        ? 'Cash & Bank'
        : formatFormalText(account_type),
    options: accounts,
  }))
);
