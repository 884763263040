import apiCall from 'apiCalls/product';
import variantApiCall from 'apiCalls/productVariant';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { useParams } from 'react-router-dom';
import { getTenantUrl } from 'utils/urlHelpers';
import { useMutation } from '@tanstack/react-query';
import ItemForm from './Form';
import { columnsGenerator, variantColumns } from './config';
import ItemDetail from './detail';

export const ProductListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Products"
      namespace="products"
      searchOptions={{ tooltip: 'Enter product name' }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const ProductEditPage = props => (
  <EditPage
    title="Product"
    namespace="products"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);

export const ProductDetailPage = () => {
  const { id } = useParams();
  const { business_id } = useTenant();

  const { mutate: deleteItem, isLoading: isDeleting } = useMutation({
    mutationFn: variantApiCall.delete.queryFn,
    onSuccess: () => {
      notification.open({
        type: 'success',
        message: 'Success',
      });
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: error.data.detail,
        duration: 10,
      });
    },
  });

  const onDelete = id => {
    if (confirm('Are you sure?')) {
      deleteItem({ business_id, id });
    }
  };

  return (
    <>
      <DetailPage
        title="Product Detail"
        namespace="products"
        apiCall={apiCall}
        listUrl={getTenantUrl(business_id, `/products`)}
        ItemDetail={ItemDetail}
        resourceName=""
        options={{ deleteEnabled: true }}
      />
      <div className="mt-4">
        <ListPage
          showBreadscrumb={false}
          createUrl={getTenantUrl(
            business_id,
            `/products/${id}/variants/create`
          )}
          title="Product variants"
          apiCall={variantApiCall}
          columnGenerator={() => variantColumns({ business_id, id, onDelete })}
          resourceName="items"
          params={{ product: id }}
          urlParamEnabled={false}
        />
      </div>
    </>
  );
};

export const ProductCreatePage = () => (
  <CreatePage
    title="Products"
    namespace="products"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);
