export const paymentMethods = {
  cash: 'Cash',
  bank: 'Bank Transfer',
  credit_card: 'Credit Card',
  cheque: 'Cheque',
  online: 'Online Payment',
  other: 'Other',
};

export const paymentMethodsOptions = Object.entries(paymentMethods).map(
  ([key, value]) => ({ value: key, label: value })
);
