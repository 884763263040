import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

export const CREATE_PURCHASE_ORDER_API_ID = 'CREATE_PURCHASE_ORDER_API_ID';

const apiCall = enhancedMakeFetchAction(
  CREATE_PURCHASE_ORDER_API_ID,
  ({ business_id, payload }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/purchase-orders`,
    }),
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
