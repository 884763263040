import { Card, Descriptions } from 'antd';
import paymentApiCall from 'apiCalls/paymentAccount/detail';
import statementApiCall from 'apiCalls/userStatement';
import { useFetchApiGet } from 'common/reduxutils';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import { StatementPage } from 'components/statement/StatementPage';
import useTenant from 'components/use-tenant';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { formatCurrency } from 'utils/formatAmount';
import { statementColumns } from './config';

export const PaymentStatementPage = props => {
  const { business_id } = useTenant();
  const params = { business_id, status__in: '1001,1002' };

  return (
    <StatementPage
      ItemCard={ItemCard}
      params={params}
      statementApiCall={statementApiCall}
      title="Balance Statement"
      statementColumns={() => statementColumns({ business_id })}
      {...props}
    />
  );
};

const ItemCard = ({ balance }) => {
  const { business_id } = useTenant();
  const { id } = useParams();
  const {
    data: item = {},
    load: fetchAccount,
    isLoading: isUserLoading,
  } = useFetchApiGet(paymentApiCall, { resourceName: 'items' });

  useEffect(() => {
    if (business_id && id) {
      fetchAccount({ business_id, id });
    }
  }, [business_id, id]);

  if (isUserLoading) return <LoadingSpinner />;
  return (
    <Card>
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Cash & Bank Book ID">
          {item.code}
        </Descriptions.Item>
        <Descriptions.Item label="Cash & Bank Book Name">
          {item.name}
        </Descriptions.Item>
        <Descriptions.Item label="Bank Name">
          {item.bank_name}
        </Descriptions.Item>
        <Descriptions.Item label="Bank Account Number">
          {item.bank_account_number}
        </Descriptions.Item>
        <Descriptions.Item label="Balance to Date">
          {formatCurrency(balance)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
