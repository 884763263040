import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_PRODUCT_DETAIL_API_ID = 'FETCH_PRODUCT_DETAIL_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_PRODUCT_DETAIL_API_ID,
  ({ business_id, id }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/products/${id}`,
    }),
    method: 'GET',
  })
);

export default apiCall;
