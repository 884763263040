import apiCall from 'apiCalls/purchase';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import PurchaseForm from './EditForm/PurchaseForm';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import InvoiceItemDetail from './invoice';

export const PurchaseListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Purchases"
      namespace="purchases"
      searchOptions={{ tooltip: 'Enter invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'users'] }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const PurchaseDetailPage = props => (
  <DetailPage
    title="Purchase Detail"
    namespace="purchases"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
    {...props}
  />
);

export const PurchaseEditPage = () => {
  return (
    <EditPage
      title="Edit Purchase"
      namespace="purchases"
      apiCall={apiCall}
      ItemForm={PurchaseForm}
      resourceName=""
    />
  );
};

export const PurchaseCreatePage = props => {
  return (
    <CreatePage
      title="Create Purchase"
      namespace="purchases"
      apiCall={apiCall}
      ItemForm={PurchaseForm}
      resourceName=""
      {...props}
    />
  );
};

export const PurchaseInvoicePage = () => (
  <DetailPage
    title="Purchase Invoice"
    namespace="purchases"
    apiCall={apiCall}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
  />
);
