import apiCall from 'apiCalls/category';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import ItemForm from './Form';
import { columnsGenerator } from './config';

export const CategoryListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Categories"
      namespace="categories"
      searchOptions={{ tooltip: 'Enter product name or odering' }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const CategoryEditPage = props => (
  <EditPage
    title="Category"
    namespace="categories"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);

export const CategoryCreatePage = props => (
  <CreatePage
    title="Categories"
    namespace="categories"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
    {...props}
  />
);
