import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_BUSINESS_DETAIL_API_ID = 'FETCH_BUSINESS_DETAIL_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_BUSINESS_DETAIL_API_ID,
  ({ id }) => ({
    endpoint: generateEndpoint({
      path: `/admin/v1/businesses/${id}`,
    }),
    method: 'GET',
  })
);

export default apiCall;
