import { assign, filter, floor, map, mapKeys, merge, reduce } from 'lodash/fp';

export const floorWithoutFixedArity = floor.convert({
  fixed: false,
});

export const assignWithoutFixedArity = assign.convert({
  fixed: false,
});

export const mergeWithoutFixedArity = merge.convert({
  fixed: false,
});

export const mapKeysWithIndex = mapKeys.convert({
  cap: false,
});

export const mapWithIndex = map.convert({
  cap: false,
});

export const reduceWithKey = reduce.convert({ cap: false });

export const filterWithKey = filter.convert({ cap: false });
