import { Button, Form, Input, InputNumber } from 'antd';
import { useEffect } from 'react';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';

const ItemForm = ({
  form,
  submiting,
  onFinish,
  initialValues = {},
  name,
  params = {},
}) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name || 'variant_form'}
      onFinish={values => {
        const payload = { ...values, ...params };
        return onFinish(payload);
      }}
      scrollToFirstError
    >
      <Form.Item name="name" label="Name (English)">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="name_ms" label="Name (Malay)">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="name_zh" label="Name (Chinese)">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="price" label="Price per unit">
        <InputNumber placeholder="" precision={2} step={0.1} />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit" loading={submiting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ItemForm;
