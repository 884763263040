import { Avatar, Dropdown, Modal, Space } from 'antd';
import useGoogleAuth from 'hooks/useGoogleAuth';
import { useNavigate } from 'react-router-dom';

const UserMenu = ({ user }) => {
  let navigate = useNavigate();
  const { signOut } = useGoogleAuth();
  const items = [
    {
      key: 'logout',
      label: 'Log out',
      onClick: () =>
        Modal.confirm({
          title: 'Are you sure?',
          onOk: () => {
            signOut();
            navigate('/login');
          },
        }),
    },
  ];

  return (
    <Dropdown arrow placement="bottomRight" menu={{ items }}>
      <Space className="cursor-pointer hover:bg-zinc-200 p-2 rounded-lg">
        {`${user.name || user.id} (${user.email})`}
        <Avatar style={{ verticalAlign: 'middle' }}>
          {user.name ? user.name.charAt(0) : 'U'}
        </Avatar>
      </Space>
    </Dropdown>
  );
};

export default UserMenu;
