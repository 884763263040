import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Divider,
  Form,
  Input,
  notification,
  Select,
  Space,
  Upload,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import branchApiCall from 'apiCalls/branch';
import orderApiCall from 'apiCalls/order';
import userApiCall from 'apiCalls/user/all';
import DatePicker from 'common/form/UIDatePicker';
import DebounceSelect from 'common/ui/DebouceSelect';
import XModal from 'components/XModal';
import { statusOptions } from 'constants/status';
import { useFormBlocker } from 'hooks';
import { formItemLayout, tailFormItemLayout } from 'utils/formConfig';
import {
  convertDateFormData,
  ensureDateFields,
  FormattedError,
  submitData,
  userSelectDisplayFn,
} from 'utils/formData';

import useTenant from 'components/use-tenant';
import OrderItemDataTable from '../OrderItemDataTable';
import OrderItemForm from './OrderItemForm';

const dateFields = ['shipping_estimated_arrived', 'date'];

const useOrderQuery = (business_id, id) => {
  return useQuery({
    queryKey: [orderApiCall.detail.queryKey, id],
    queryFn: () => {
      if (id) {
        return orderApiCall.detail.queryFn({ business_id, id });
      }

      return Promise.resolve('');
    },
  });
};

const ensureFormData = values => {
  const payload = ensureDateFields(values, dateFields);
  if (payload.user && payload.user.id) {
    return {
      ...payload,
      user: payload.user.id,
    };
  }

  return payload;
};

const OrderForm = ({ form, initialValues = { status: 1001 }, name }) => {
  const navigate = useNavigate();
  const setShouldBlock = useFormBlocker(form);

  const urlParams = useParams();
  const { id } = urlParams;
  const { business_id } = useTenant();
  const formData = Form.useWatch([], form) || {};

  const orderId = id || formData?.id;
  const { data: order = {} } = useOrderQuery(business_id, orderId);

  const [shouldGoBack, setShouldGoBack] = useState(false);

  const { mutate: createOrder, isLoading: creatingOrder } = useMutation({
    mutationFn: orderApiCall.create.queryFn,
    onSuccess: createdOrder => {
      if (shouldGoBack) {
        notification.open({
          type: 'success',
          message: 'Success',
        });
        navigate(-1);
      } else {
        form.setFieldsValue({
          id: createdOrder.id,
          code: createdOrder.code,
          status: createdOrder.status,
        });
        notification.open({ message: 'Saved' });
      }
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const { mutate: updateOrder, isLoading: updatingOrder } = useMutation({
    mutationFn: orderApiCall.edit.queryFn,
    onSuccess: () => {
      if (shouldGoBack) {
        notification.open({
          type: 'success',
          message: 'Success',
        });
        navigate(-1);
      } else {
        notification.open({ message: 'Saved' });
      }
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: <FormattedError error={error} />,
        duration: 10,
      });
    },
  });

  const dataItem = {
    ...initialValues,
    ...order,
    branch: initialValues.branch?.id,
  };

  // detect changes for auto save
  useEffect(() => {
    const isAutoSave =
      !formData.id &&
      !!formData.user &&
      formData.status != 1002 &&
      !!formData.date;

    if (isAutoSave) {
      setShouldBlock(false);
      form.submit();
    }
  }, [formData]);

  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={ensureFormData(dataItem)}
      name={name || 'order_checkout_form'}
      onFieldsChange={() => setShouldBlock(!!formData.id)}
      onFinish={values => {
        setShouldBlock(false);
        if (values.id) {
          return submitData(
            updateOrder,
            convertDateFormData({ business_id, ...values }, dateFields),
            urlParams,
            ['file']
          );
        }

        return submitData(
          createOrder,
          convertDateFormData({ business_id, ...values }, dateFields),
          urlParams,
          ['file']
        );
      }}
      scrollToFirstError
    >
      <Form.Item name="id">
        <Input type="hidden" />
      </Form.Item>
      <Form.Item name="status" label="Status">
        <Select>
          {statusOptions.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="user" label="Account" required>
        <DebounceSelect
          apiCall={userApiCall}
          initialOption={{
            id: initialValues.user?.id,
            name: initialValues.user?.name,
            account_type: initialValues.user?.account_type,
          }}
          params={{
            business_id,
            account_type__in: 'customer,supplier,payment',
          }}
          placeholder="Select account"
          displayFn={userSelectDisplayFn}
          fieldNames={{ label: 'name', value: 'id' }}
          onSelect={(_selectedUserId, selectedUser) => {
            // Only pre-fill for non-payment accounts (creditor or debitor accounts)
            if (selectedUser && selectedUser.account_type != 'payment') {
              form.setFieldsValue({
                billing_address: selectedUser.address1,
                contact_person: selectedUser.personInCharge,
                phone_number: selectedUser.phone,
                customer_email: selectedUser.email,
              });
            }
          }}
        />
      </Form.Item>
      <Form.Item name="billing_address" label="Billing Address">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="contact_person" label="Contact Person">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="phone_number" label="Phone Number">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="customer_email" label="Email Address">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item name="shipping_address" label="Shipping Address">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="shipping_name" label="Shipping Contact">
        <Input placeholder="" />
      </Form.Item>
      <Form.Item name="shipping_phone_number" label="Shipping Phone Number">
        <Input placeholder="" />
      </Form.Item>

      <Form.Item label="Invoice No.">
        {dataItem.code || '[auto generated]'}
      </Form.Item>
      <Form.Item name="date" label="Date" required>
        <DatePicker format={'YYYY-MM-DD'} />
      </Form.Item>
      <Form.Item name="branch" label="Branch">
        <DebounceSelect
          apiCall={branchApiCall.list}
          params={{ business_id }}
          placeholder="Select branch"
          fieldNames={{ label: 'name', value: 'id' }}
        />
      </Form.Item>
      <Form.Item name="reference_number" label="Reference No.">
        <Input placeholder="" />
      </Form.Item>

      <Divider>Add product to order</Divider>

      <XModal title={'New item'} isReady={!!formData.id}>
        <OrderItemForm params={{ order: orderId }} />
      </XModal>

      <OrderItemDataTable
        dataSource={dataItem.order_items || []}
        renderEditOrderItemForm={id => (
          <OrderItemForm params={{ order: orderId, id: id }} />
        )}
        order={order}
      />

      <Form.Item name="notes" label="Notes">
        <Input.TextArea rows={5} />
      </Form.Item>
      <Form.Item name="notes_admin" label="Personal Notes">
        <Input.TextArea rows={5} />
      </Form.Item>
      <Form.Item
        name="file"
        label="Attachment"
        valuePropName="fileList "
        getValueFromEvent={e => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <Upload.Dragger listType="picture" beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={creatingOrder || updatingOrder}
            onClick={() => {
              setShouldGoBack(true);
            }}
          >
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default OrderForm;
