import React, { useState } from 'react';

const { Modal, Button, Form } = require('antd');

const XModal = ({ onOk, children, isReady = true, ...props }) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    form.submit();
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    form.resetFields();
    closeModal();
  };

  return (
    <>
      <Button onClick={showModal} disabled={!isReady}>
        {props.title}
      </Button>
      <Modal
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width={1000}
        {...props}
      >
        {React.cloneElement(children, {
          form,
          closeModal,
          handleOk,
          handleCancel,
        })}
      </Modal>
    </>
  );
};

export default XModal;
