import { formatDate, formatDateTime } from 'utils/dateUtils';
import { formatNumber } from 'utils/formatAmount';

export function formatTableColumns(columns) {
  return columns.map(object => {
    const { type } = object;
    if (type === 'number') {
      return {
        ...object,
        align: 'right',
      };
    }
    if (type === 'formatNumber') {
      return {
        ...object,
        render: value => (!!value || value === 0 ? formatNumber(value) : '-'),
        align: 'right',
      };
    }
    if (type === 'date') {
      return {
        ...object,
        render: value => formatDate(value),
      };
    }
    if (type === 'dateTime') {
      return {
        ...object,
        render: value => formatDateTime(value),
      };
    }
    return object;
  });
}
