import { Card, Descriptions, Typography } from 'antd';

import { formatDate, formatDateTime } from 'utils/dateUtils';

const { Item: DescItem } = Descriptions;
const { Title } = Typography;

const ItemDetail = ({ item }) => {
  return (
    <div className="flex flex-row gap-4 justify-between">
      <div className="flex flex-col gap-6 py-4 ">
        <Card title={<Title level={3}>Entry Detail</Title>}>
          <Descriptions column={1} bordered>
            <DescItem label="Date">{formatDate(item.date)}</DescItem>
            <DescItem label="Particulars">{item.journal_type}</DescItem>
            <DescItem label="Debit">{item.debit}</DescItem>
            <DescItem label="Credit">{item.credit}</DescItem>
            <DescItem label="Created Date">
              {formatDateTime(item.created)}
            </DescItem>
            <DescItem label="Modified Date">
              {formatDateTime(item.modified)}
            </DescItem>
          </Descriptions>
        </Card>
      </div>
    </div>
  );
};

export default ItemDetail;
