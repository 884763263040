import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { formatDate, formatDateTime } from 'utils/dateUtils';
import { formatCurrency } from 'utils/formatAmount';
import { getTenantUrl } from 'utils/urlHelpers';

export const columnsGenerator = ({ business_id }) => [
  {
    title: 'Date',
    dataIndex: 'date',
    align: 'center',
    render: value => formatDate(value),
  },
  {
    title: 'Purchase Return Number',
    dataIndex: 'code',
    align: 'center',
  },
  {
    title: 'Debtor Name',
    dataIndex: 'user',
    render: user => {
      if (!user) return null;
      return user.name;
    },
  },
  {
    title: 'Amount',
    dataIndex: 'total_amount',
    align: 'right',
    render: value => formatCurrency(value),
  },
  {
    title: 'Modifed Date',
    dataIndex: 'modified',
    render: value => formatDateTime(value),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Button.Group>
        <Button shape="round">
          <Link
            to={getTenantUrl(business_id, `/purchase-returns/${record.id}`)}
          >
            View
          </Link>
        </Button>
        <Button shape="round">
          <Link
            to={getTenantUrl(
              business_id,
              `/purchase-returns/${record.id}/edit`
            )}
          >
            Edit
          </Link>
        </Button>
        <Button shape="round">
          <Link
            to={getTenantUrl(
              business_id,
              `/purchase-returns/${record.id}/invoice`
            )}
          >
            Invoice
          </Link>
        </Button>
      </Button.Group>
    ),
  },
];
