import apiCall from 'apiCalls/productVariant';
import { CreatePage, EditPage } from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { useParams } from 'react-router-dom';
import { getTenantUrl } from 'utils/urlHelpers';
import ItemForm from './Form';

export const VariantCreatePage = props => {
  const { product_id } = useParams();
  const { business_id } = useTenant();

  return (
    <CreatePage
      title="Variants"
      namespace="products"
      apiCall={apiCall}
      listUrl={getTenantUrl(business_id, `/products/${product_id}`)}
      ItemForm={ItemForm}
      resourceName="item"
      params={{ product: product_id, variant_type: 'size' }}
      {...props}
    />
  );
};

export const VariantEditPage = props => {
  const { product_id, id } = useParams();
  const { business_id } = useTenant();

  return (
    <EditPage
      title="Variants"
      namespace="products"
      apiCall={apiCall}
      listUrl={getTenantUrl(business_id, `/products/${product_id}`)}
      ItemForm={ItemForm}
      resourceName="item"
      params={{ product: product_id, variant_type: 'size' }}
      {...props}
    />
  );
};
