import { API_BASE_URL } from 'config/env';
import qs from 'qs';

interface EndpointParams {
  path: string;
  params?: Record<string, any>;
}

export const generateEndpoint = ({ path, params }: EndpointParams): string => {
  const url = `${API_BASE_URL}${path}`;
  const queryString = qs.stringify(params, { arrayFormat: 'brackets' });
  return queryString ? `${url}?${queryString}` : url;
};

export function getTenantUrl(business_id: string, url: string) {
  const path = url.startsWith('/') ? url.slice(1) : url;
  return `/${business_id}/${path}`;
}
