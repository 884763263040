import variantApiCall from 'apiCalls/productVariant';
import { ListPage } from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { variantColumnsGenerator } from './config';

export const VariantStockListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="Stock Tracking"
      namespace="stock-tracking"
      searchOptions={{ tooltip: false }}
      showBreadscrumb={true}
      apiCall={variantApiCall}
      columnGenerator={() => variantColumnsGenerator({ business_id })}
      resourceName="items"
      extra={<></>}
    />
  );
};
