import moment from 'moment';

export default date => moment.unix(date).format('MMMM Do, YYYY LT');

export const timeAgo = timeData => {
  // timeData type must be a string or number
  return timeData ? moment(timeData).fromNow() : null;
};

export const formatDateTime = (timeData, dateFormat = 'DD/MM/YYYY h:mma') => {
  if (isZeroDate(timeData)) return null;
  return timeData ? moment(timeData).format(dateFormat) : null;
};

export const formatDate = (timeData, format = 'DD/MM/YYYY') => {
  return formatDateTime(timeData, format);
};

export const formatISODate = isoStringDatetime => {
  if (typeof isoStringDatetime === 'string')
    return isoStringDatetime
      ? moment(isoStringDatetime).format('MMM DD, YYYY')
      : null;
  if (!Number.isNaN(isoStringDatetime))
    return moment.unix(isoStringDatetime).format('MMMM Do, YYYY LT');
  return isoStringDatetime;
};

export const isZeroDate = timeData => {
  const parsedDate = timeData ? moment(timeData).format('YYYY-MM-DD') : null;
  if (parsedDate === '0001-01-01' || parsedDate == undefined) return true;
  return false;
};
