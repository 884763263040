import apiCall from 'apiCalls/salesQuotation';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import SalesQuotationForm from './forms/SalesQuotationForm';
import InvoiceItemDetail from './invoice';

export const SalesQuotationListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Quotations"
      namespace="sales-quotations"
      searchOptions={{ tooltip: 'Search by invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'users'] }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const SalesQuotationDetailPage = props => (
  <DetailPage
    title="Quotation Detail"
    namespace="sales-quotations"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
  />
);

export const SalesQuotationEditPage = props => (
  <EditPage
    title="Quotation Edit"
    namespace="sales-quotations"
    apiCall={apiCall}
    ItemForm={SalesQuotationForm}
    resourceName=""
    {...props}
  />
);

export const SalesQuotationCreatePage = props => (
  <CreatePage
    title="Quotation Create"
    namespace="sales-quotations"
    apiCall={apiCall}
    ItemForm={SalesQuotationForm}
    resourceName=""
    {...props}
  />
);

export const SalesQuotationInvoicePage = props => (
  <DetailPage
    title="Quotation Invoice"
    namespace="sales-quotations"
    apiCall={apiCall}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
    {...props}
  />
);
