import apiCall from 'apiCalls/purchaseOrder';
import {
  CreatePage,
  DetailPage,
  EditPage,
  ListPage,
} from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import PurchaseForm from './EditForm/PurchaseOrderForm';
import { columnsGenerator } from './config';
import ItemDetail from './detail';
import InvoiceItemDetail from './invoice';

export const PurchaseOrderListPage = () => {
  const { business_id } = useTenant();
  return (
    <ListPage
      title="All Purchase Orders"
      namespace="purchase-orders"
      searchOptions={{ tooltip: 'Enter invoice number, username or email' }}
      filterOptions={{ filters: ['date', 'users'] }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const PurchaseOrderDetailPage = props => (
  <DetailPage
    title="Purchase Order Detail"
    namespace="purchase-orders"
    apiCall={apiCall}
    ItemDetail={ItemDetail}
    resourceName=""
    {...props}
  />
);

export const PurchaseOrderEditPage = () => {
  return (
    <EditPage
      title="Edit Purchase Order"
      namespace="purchase-orders"
      apiCall={apiCall}
      ItemForm={PurchaseForm}
      resourceName=""
    />
  );
};

export const PurchaseOrderCreatePage = props => {
  return (
    <CreatePage
      title="Create Purchase Order"
      namespace="purchase-orders"
      apiCall={apiCall}
      ItemForm={PurchaseForm}
      resourceName=""
      {...props}
    />
  );
};

export const PurchaseOrderInvoicePage = () => (
  <DetailPage
    title="Purchase Invoice"
    namespace="purchase-orders"
    apiCall={apiCall}
    ItemDetail={InvoiceItemDetail}
    resourceName=""
  />
);
