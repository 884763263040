import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, notification } from 'antd';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import apiCall from 'apiCalls/account/auth';
import { SITE_NAME } from 'config/env';
import { setToken } from 'utils/auth';

const AdminLoginPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const expired = searchParams.get('expired');
    if (expired) {
      notification.open({
        type: 'warning',
        message: 'Session Expired',
        description: 'Please login again to continue',
      });
    }
  }, []);

  const { mutate: submit, isLoading } = useMutation({
    mutationFn: apiCall.queryFn,
    onSuccess: ({ token, user }) => {
      setToken(token, user);
      notification.open({
        type: 'success',
        message: 'Success',
      });

      const url = searchParams.get('url') || '';
      if (url && url.startsWith('/')) {
        navigate(url);
      } else {
        navigate('/');
      }
    },
    onError: error => {
      notification.open({
        type: 'error',
        message: 'Error!',
        description: error.data.error,
        duration: 10,
      });
    },
  });

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm text-center">
        <h1>{SITE_NAME}</h1>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Form
          form={form}
          onFinish={values => {
            submit({ payload: values });
          }}
          className="space-y-6"
        >
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Email address
          </label>
          <div className="mt-2">
            <Form.Item name="email">
              <Input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </Form.Item>
          </div>
          <div className="flex items-center justify-between">
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Password
            </label>
          </div>
          <div className="mt-2">
            <Form.Item name="password">
              <Input.Password
                autoComplete="password"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </Form.Item>
          </div>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              className=""
            >
              Sign in
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AdminLoginPage;
