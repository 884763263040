import { GuestRoute } from 'common/ui/AuthRoute';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';

const GuestLayout = () => (
  <GuestRoute>
    <Outlet />
    <Footer />
  </GuestRoute>
);

export default GuestLayout;
