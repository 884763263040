import { API_BASE_URL } from 'config/env';
import axios from 'utils/enhancedAxios';

const UDPATE_PURCHASE_ITEMS_API_ID = 'UPDATE_PURCHASE_ITEMS';

const apiCall = orderItemsInput => {
  return Promise.all(
    orderItemsInput.map(({ id, _destroy, ...payload }) => {
      if (!id) {
        const axiosOptions = {
          url: `${API_BASE_URL}/admin/v1/purchase-items`,
          method: 'POST',
          data: payload,
        };
        axios(axiosOptions);
      } else if (_destroy) {
        const axiosOptions = {
          url: `${API_BASE_URL}/admin/v1/purchase-items/${id}`,
          method: 'DELETE',
        };
        axios(axiosOptions);
      } else {
        const axiosOptions = {
          url: `${API_BASE_URL}/admin/v1/purchase-items/${id}`,
          method: 'PUT',
          data: payload,
        };
        axios(axiosOptions);
      }
    })
  );
};

export default {
  queryKey: UDPATE_PURCHASE_ITEMS_API_ID,
  queryFn: apiCall,
};
