import { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash/fp';
import { useQuery } from '@tanstack/react-query';

// IMPORTANT: DON'T USE THIS FUNCTION
// This is legacy
// Use useQuery of react-query instead
// https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
const useFetchApiGet = (apiCall, options = {}) => {
  const { resourceName = 'item' } = options;

  const [params, setParams] = useState({});
  const [delay, setDelay] = useState(true);

  const { data: rawData, error, isLoading, refetch, isRefetching } = useQuery({
    queryKey: [apiCall.queryKey, params],
    queryFn: () => {
      console.log('params in queryFn', params);
      return apiCall.queryFn(params);
    },
    enabled: false,
  });

  const data = resourceName ? get(resourceName, rawData) : rawData;
  const load = newParams => {
    setParams(newParams);
    setDelay(false);
  };

  useEffect(() => {
    if (!delay && !isEmpty(params)) {
      refetch();
    }
  }, [delay]);

  return {
    load,
    rawData,
    data: data ? data : rawData,
    isLoading,
    refresh: refetch,
    refreshing: isRefetching,
    error,
  };
};

export default useFetchApiGet;
