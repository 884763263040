export async function setToken(token, user) {
  localStorage.setItem('adminSession.token', token);
  localStorage.setItem('adminSession.user', JSON.stringify(user));
}

export async function deleteToken() {
  localStorage.removeItem('adminSession.token');
  localStorage.removeItem('adminSession.user');
}

export function getToken() {
  return localStorage.getItem('adminSession.token');
}

export function getUser() {
  const user = localStorage.getItem('adminSession.user');
  return JSON.parse(user);
}
