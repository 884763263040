import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_BUSINESS_CREATE_API_ID = 'FETCH_BUSINESS_CREATE_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_BUSINESS_CREATE_API_ID,
  ({ payload }) => ({
    endpoint: generateEndpoint({ path: `/admin/v1/businesses` }),
    method: 'POST',
    body: payload,
    notify: true,
  })
);

export default apiCall;
