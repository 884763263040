import apiCall from 'apiCalls/branch';
import { CreatePage, EditPage, ListPage } from 'common/ui/AdminCRUD/page';
import useTenant from 'components/use-tenant';
import ItemForm from './Form';
import { columnsGenerator } from './config';

export const BranchListPage = () => {
  const { business_id } = useTenant();

  return (
    <ListPage
      title="All Branches"
      namespace="branches"
      searchOptions={{ tooltip: 'Enter branch name or ordering' }}
      apiCall={apiCall}
      columnGenerator={() => columnsGenerator({ business_id })}
    />
  );
};

export const BranchEditPage = () => (
  <EditPage
    title="Branch"
    namespace="branches"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);

export const BranchCreatePage = () => (
  <CreatePage
    title="Branches"
    namespace="branches"
    apiCall={apiCall}
    ItemForm={ItemForm}
    resourceName=""
  />
);
