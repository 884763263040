import { Layout, Menu } from 'antd';
import useAdminAuth from 'hooks/useAdminAuth';
import { useParams } from 'react-router-dom';
import { getAdminItems, getManagerItems, openKeys } from './config';

const SideMenu = () => {
  const { user } = useAdminAuth();
  const { business_id } = useParams();
  const menuItems =
    user && user.account_type === 'admin'
      ? getAdminItems(business_id)
      : getManagerItems(business_id);

  return (
    <Layout.Sider theme="dark" breakpoint="xl" collapsedWidth="0">
      <Menu
        theme="dark"
        mode="inline"
        items={menuItems}
        defaultOpenKeys={openKeys}
      />
    </Layout.Sider>
  );
};

export default SideMenu;
