import { Spin } from 'antd';

const LoadingSpinner = () => (
  <div id="content">
    <div className="container" style={{ textAlign: 'center', paddingTop: 100 }}>
      <Spin size="large"></Spin>
    </div>
  </div>
);

export default LoadingSpinner;
