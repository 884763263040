const defaultLocale = 'en-UK';

export default number => {
  if (!number) {
    return '-';
  }

  const formatedNumber = Math.abs(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (parseFloat(number) < 0) {
    return `-$${formatedNumber}`;
  }

  return `$${formatedNumber}`;
};

export const formatNumber = value => {
  if (!value) return null;
  return new Intl.NumberFormat('en-US').format(value);
};

export const formatCurrency = (value, options = {}) => {
  // setting default locale is VN. TODO: set default locale by user's localization.
  // It means that user's language is en, locale will be en-US. Otherwise, it will be vi-VN if the language is VN

  if (!value || parseFloat(value) === 0) return '-';

  const {
    locale = defaultLocale,
    currency = 'MYR',
    currencyDisplay = 'symbol',
    ...others // other options
  } = options;

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: currencyDisplay,
    ...others,
  }).format(value);
};
