import { Layout } from 'antd';
import { PrivateRoute } from 'common/ui/AuthRoute';
import ScrollToTop from 'common/ui/ScrollToTop';
import SideMenu from 'components/SideMenu';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';

const TenantLayout = () => {
  return (
    <PrivateRoute>
      <Layout>
        <SideMenu />
        <Layout>
          <Header />
          <Layout.Content style={{ margin: '16px' }}>
            <div
              className="site-layout-background p-6"
              style={{ minHeight: 360 }}
            >
              <Outlet />
            </div>
          </Layout.Content>
          <Footer />
          <ScrollToTop />
        </Layout>
      </Layout>
    </PrivateRoute>
  );
};

export default TenantLayout;
