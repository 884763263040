import { enhancedMakeFetchAction } from 'common/reduxutils';
import { generateEndpoint } from 'utils/urlHelpers';

const FETCH_SALES_RETURN_LIST_API_ID = 'FETCH_SALES_RETURN_LIST_API';

const apiCall = enhancedMakeFetchAction(
  FETCH_SALES_RETURN_LIST_API_ID,
  ({ business_id, ...params }) => ({
    endpoint: generateEndpoint({
      path: `/tenant/v1/businesses/${business_id}/sales-returns`,
      params,
    }),
    method: 'GET',
  })
);

export default apiCall;
